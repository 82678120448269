import React, { useEffect } from "react";
import { contentHeader, TableHeader } from "../../Constancs/cssconst";
import { getList, URLPRIFIX } from "../../functions/api";
import ListView from "./ListView";
import { getlocal } from "../../functions/localstorage";

const UpcomingDueDate = ({ store, props }) => {
  useEffect(() => {
    const user = getlocal("system")?.user || {};
    getList({
      listUrl: "/tasks/getprojectstasklist",
      field: user?.role == "1" ? "duedate" : "assigned_to",
      search: user?.role == "1" ? "" : user?.id,
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("getprojectstasklist", res);
      //   init.Duedatelistdoc = res?.data?.list;
      store.setvalue("Duedatelistdoc", res?.data?.list);
    });
  }, []);

  return (
    <>
      <h1 className={contentHeader}>Upcoming Due Date</h1>
      <ListView
        Tablehead={"bg-tertiary min-w-[150px]  pr-[20px] " + TableHeader}
        Tablerecord=" pr-[20px] max-sm:text-[26px] md:text-[20px] lg:text-[14px] xl:text-[14px] cursor-pointer "
        width={"95%"}
        height={"300px"}
        onclick={(index) => {
          store.navTo("/" + URLPRIFIX + "/documents/view/" + index);
        }}
        header={[
          ["Document Title", "task_title"],
          ["Due Date", "dueDate"],
        ]}
        records={store.getvalue("Duedatelistdoc")}
      />
      {/* <div className="h-[330px] overflow-x-auto flex items-center flex-col gap-4">
            {store.getvalue("Duedatelistdoc") &&
              store.getvalue("Duedatelistdoc").map((item, index) => (
                <div
                  key={index}
                  className="bg-secondary rounded-2xl p-3 w-full flex items-center relative"
                  onClick={() => {
                    store.navTo("/demo/tasks/view/" + item.id);
                  }}
                >
                  <h1 className="text-primary font-semibold text-[18px]">
                    {item.task_title + " - "}
                    <span className="font-light">
                      {item.dueDate.split("T")[0]}
                    </span>
                  </h1>
                  <div className="absolute right-3 bg-secondary">
                    <IoChevronForwardOutline />
                  </div>
                </div>
              ))}
          </div> */}
    </>
  );
};

export default UpcomingDueDate;
