import React from "react";

const FormView = ({ children, actions = [] }) => {
  return (
    <div className=" h-full py-10 px-5 relative">
      <div className=" h-[95%] w-full overflow-x-auto">{children}</div>
      <div className=" h-[5%] w-full flex justify-end items-center pl-[2%] pt-[3%] gap-5">
        {actions.map((action, index) => {
          if (action.visible) {
            return (
              <button
                key={index}
                className={
                  "py-2 px-6 rounded-xl font-bold text-[12px]" +
                  (action.type === "primary"
                    ? " bg-primary text-secondary"
                    : " bg-tertiary text-primary")
                }
                onClick={() => {
                  action.onclick();
                }}
              >
                {action.Name}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default FormView;
