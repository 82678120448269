import { FaImage, FaImages } from "react-icons/fa";
import { baseurl, GetFile, login } from "../../functions/api";
import { useStore } from "../StateProvider";
import { FaFilePdf } from "react-icons/fa6";

const Attachement = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  fileurl,
  doubleline = false,
  readonly = false,
  showImageOnly = false,
  onclick = () => { },
}) => {
  const store = useStore();
  let props = store.getprops(name) || {};

  if (!props.hidden && store.getvalue(name) != undefined) {
    if (showImageOnly && fileurl) {
      return (
        <img
          src={baseurl + "/files/preview?key=" + fileurl}
          alt="Company Logo"
          className={className || "rounded-full object-cover"}
        />
      );
    }
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[5px] md:text-[14px] mb-8 ml-2 md:mb-0 w-10 h-1 px-0 py-0 md:py-0 md:w-full md:h-full md:px-3 "
            : className.field) +
          (doubleline ? "flex-col gap-1" : "flex-row items-center gap-1 md:gap-3")
        }
      >
        {/* <label
          className={
            className.lable === ""
              ? "w-fit  min-w-[200px] max-w-full"
              : className.lable
          }
          onClick={() => {
            document.getElementById(name).focus();
          }}
        >
          {label}
        </label> */}
 {/* {fileurl && (
        <img
          src={baseurl + "/files/preview?key=" + fileurl}
          alt="Company Logo"
          className={className || " rounded-full object-cover"}
        />
      )} */}
        {fileurl === undefined ? (
          <button
            className={
              className.input === ""
                ? "w-full  rounded-md font-medium flex justify-start items-center gap-1 md:gap-4 px-4 py-2 border-b hover:bg-Alice_Blue "
                : className.input
            }
            id={name}
            disabled={readonly}
            onClick={() => {              
              onclick(store.getvalue(name));
              store.setvalue("viewAttachement", store.getvalue(name));
              store.setvalue("viewAttachementname", name);
            }}
          >
            {store.getvalue(name).includes("pdf") ? (
              <FaFilePdf className="h-8 w-12 text-[#e83b3b]" />
            ) : (
              <FaImage className="h-8 w-8 text-[#443be8]" />
            )}

            <div className="max-w-full overflow-hidden">
              {store.getvalue(name)
                ? store.getvalue(name).split("/")[1]
                : "No file URL"}
            </div>
          </button>
        ) : (
          <button
            className={
              className.input === ""
                ? "md:w-full w-8 md:h-full w-6 h-2 rounded-md md:font-medium md:flex md:justify-start md:items-center gap-1 md:gap-4 px-0 md:px-4  py-2 border-b hover:bg-Alice_Blue overflow-ellipsis"
                : className.input
            }
            id={name}
            disabled={readonly}
            onClick={() => {
              onclick(fileurl);
              store.setvalue("viewAttachement", fileurl);
              store.setvalue("viewAttachementname", name);
            }}
          >
            {store.getvalue(name).includes("pdf") ? (
              <FaFilePdf className="h-8 w-12 text-[#e83b3b]" />
            ) : (
              <FaImage className="h-8 w-8 text-[#443be8]" />
            )}
            <div className="max-w-full overflow-hidden">
              {fileurl.split("/")[1] || "No file URL"}
            </div>
          </button>
        )}
        {store.getvalue("viewAttachement") !== undefined &&
          store.getvalue("viewAttachementname") === name &&
          store.getvalue("viewAttachement") !== "" && (
            <div className="absolute top-0 left-0 h-full w-full z-50">
              <button
                onClick={() => {
                  store.setvalue("viewAttachement", "");
                }}
                className="text-[8px] md:text-[20px] px-2 py-1 md:px-6 md:py-3 rounded-full absolute right-2 md:right-5 top-3 md:top-10 bg-primary text-secondary "
              >
                X
              </button>
              {store.getvalue("viewAttachement").includes("pdf") ? (
                <iframe
                  className="h-[98%] w-full bg-tertiary"
                  src={
                    baseurl +
                    "/files/preview?key=" +
                    store.getvalue("viewAttachement")
                  }
                />
              ) : (
                <img
                  className=" h-[98%] bg-tertiary"
                  s
                  src={
                    baseurl +
                    "/files/preview?key=" +
                    store.getvalue("viewAttachement")
                  }
                />
              )}
            </div>
          )}
      </div>
    );
  }
};

export default Attachement;
