export function getFulllocal() {
  if (JSON.parse(localStorage.getItem("appState"))) {
    return JSON.parse(localStorage.getItem("appState"));
  } else {
    setlocal({
      system: { login: false },
      records: {},
      properties: {},
    });
    return {
      system: { login: false },
      records: {},
      properties: {},
    };
  }
}
export function getlocal(key) {
  if (JSON.parse(localStorage.getItem("appState"))) {
    return JSON.parse(localStorage.getItem("appState"))[key];
  } else {
    setlocal({
      system: { login: false },
      records: {},
      properties: {},
    });
  }
  return JSON.parse(localStorage.getItem("appState"))[key];
}
export function setlocal(state) {
  localStorage.setItem("appState", JSON.stringify(state));
}

export function getStorage() {
  const data = {
    company: [
      {
        company_logo: "",
        company_ID: "12",
        company_name: "Sk Solutions",
        company_address_line1:
          "601/602, Trade World, B-wing, Kamala Mill Compound, Lower Parel, Mumbai-400013",
        company_address_line2: "",
        company_address_city: "Lower Parel",
        company_address_pincode: "400013",
        company_address_state: "Mumbai",
        company_phone_alternative: "09876543457",
        company_website_url: "http://sksolutions.com",
        company_phone: "09876543457",
        company_email: "info@sk.com",
        company_status: "true",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        company_logo: "",
        company_ID: "13",
        company_name: "KS Solutions",
        company_address_line1:
          "601/602, Trade World, B-wing, Kamala Mill Compound, Lower Parel, Mumbai-400013",
        company_address_line2: "",
        company_address_city: "Lower Parel",
        company_address_pincode: "400013",
        company_address_state: "Mumbai",
        company_phone_alternative: "",
        company_website_url: "http://kssolutions.com",
        company_phone: "09876543457",
        company_email: "info@ks.com",
        company_status: "true",
        modifiedBy: 1,
        createdBy: 1,
      },
    ],
    group: [
      {
        groupName: "Current Asset",
        parent: "0",
        type: "1",
        status: "1",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        groupName: "Cash",
        parent: "1",
        type: "1",
        status: "1",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        groupName: "Current Liabilities",
        parent: "0",
        type: "2",
        status: "1",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        groupName: "Sundry Creditors",
        parent: "3",
        type: "2",
        status: "1",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        groupName: "Sundry Debtors",
        parent: "1",
        type: "1",
        status: "1",
        modifiedBy: 1,
        createdBy: 1,
      },
    ],
    ledger: [],
    invoice: [],
    Receipt: [
      {
        salesNumber: "",
        customerName: "Aditya Enterprises",
        DepositTo: "Axis Bank",
        amountReceived: "200000",
        date: "2024-11-24T00:00:00Z",
        Transaction: [
          {
            salesNumber: "",
            customerName: "1",
            salesOrder: "1",
            date: "2024-11-24T00:00:00Z",
            Transaction: [
              {
                Group: "1",
                Quantity: "1",
                Rate: "40000",
                Amount: "40000",
              },
            ],
            subTotal: "40000.00",
            discountPercentage: "10",
            discountType: "%",
            discountValue: "4000.00",
            GSTType: "TAX",
            taxing: "1",
            taxValue: "6480.00",
            Total: "42480.00",
            notes: "",
            modifiedBy: 1,
            createdBy: 1,
            payment: "42479",
          },
        ],
        subTotal: 0,
        discountPercentage: 0,
        discountType: "%",
        amountUsed: "42479.00",
        GSTType: "",
        taxing: "",
        amountRefund: 0,
        Total: "42479.00",
        amountExcess: 0,
        notes: "",
        discountValue: "0.00",
        taxValue: "0.00",
        modifiedBy: 1,
        createdBy: 1,
      },
      {
        receiptNumber: "",
        customerName: "1",
        DepositTo: "1",
        amountReceived: "2000",
        receiptdate: "2024-11-29T00:00:00Z",
        Transaction: [
          {
            Group: "1",
            Quantity: "35000",
            Rate: "5000",
            Amount: "5000",
          },
        ],
        subTotal: 0,
        discountPercentage: 0,
        discountType: "%",
        amountUsed: "5000.00",
        GSTType: "",
        taxing: "",
        amountRefund: 0,
        Total: "5000.00",
        amountExcess: 0,
        notes: "",
        terms: "",
        modifiedBy: 1,
        createdBy: 1,
      },
    ],
    Purchase: [],
    payment: [],
    journal: [
      {
        debitAccountId: "1",
        creditAccountId: "1",
        amount: "42480.00",
        description: "Auto Created by invoice Creation",
        createdBy: 1,
      },
      {
        debitAccountId: "1",
        creditAccountId: "1",
        amount: "42480.00",
        description: "Auto Created by invoice Creation",
        createdBy: "1",
      },
      {
        debitAccountId: "Aditya Enterprises",
        creditAccountId: "Axis Bank",
        amount: "200000",
        description: "Auto Created by invoice Creation",
        createdBy: "1",
      },
      {
        debitAccountId: "1",
        creditAccountId: "1",
        amount: "44800.00",
        description: "Auto Created by invoice Creation",
        createdBy: "1",
      },
      {
        debitAccountId: "1",
        creditAccountId: "1",
        amount: "44800.00",
        description: "Auto Created by invoice Creation",
        createdBy: "1",
      },
      {
        customer_id: "1",
        company_id: "1",
        invoice_total: "40320.00",
        created_by: "1",
        modified_by: "1",
      },
      {
        customer_id: "1",
        company_id: "1",
        invoice_total: "40320.00",
        created_by: "1",
        modified_by: "1",
      },
      {
        customer_id: "1",
        company_id: "1",
        invoice_total: "40320.00",
        created_by: "1",
        modified_by: "1",
      },
      {
        customer_id: "1",
        company_id: "1",
        invoice_no: "INR-101",
        invoice_date: "2024-11-26T06:21:46.690Z",
        invoice_total: "42480.00",
        created_by: "1",
        modified_by: "1",
      },
      {
        customer_id: "1",
        company_id: 1,
        invoice_no: "inv-101",
        invoice_date: "2024-11-26T06:32:32.114Z",
        invoice_due_date: "2024-11-26T06:32:32.114Z",
        invoice_tds_flag: true,
        invoice_tds_id: 0,
        invoice_customer_notes: "string",
        invoice_terms_and_conditions: "string",
        invoice_sub_total: "25000.00",
        invoice_discount: "2500.00",
        invoice_total: "26550.00",
        invoice_status: true,
        created_by: "1",
        created_date: "2024-11-26T06:32:32.114Z",
        modified_by: "1",
        modified_date: "2024-11-26T06:32:32.114Z",
        delete_status: true,
      },
      {
        customer_id: "1",
        company_id: 1,
        invoice_no: "inv-101",
        invoice_date: "2024-11-26T06:32:32.114Z",
        invoice_due_date: "2024-11-26T06:32:32.114Z",
        invoice_tds_flag: true,
        invoice_tds_id: 0,
        invoice_customer_notes: "string",
        invoice_terms_and_conditions: "string",
        invoice_sub_total: "40000.00",
        invoice_discount: "4000.00",
        invoice_total: "43200.00",
        invoice_status: true,
        created_by: "1",
        created_date: "2024-11-26T06:32:32.114Z",
        modified_by: "1",
        modified_date: "2024-11-26T06:32:32.114Z",
        delete_status: true,
      },
      {
        customer_id: "1",
        company_id: 1,
        invoice_no: "inv-101",
        invoice_date: "2024-11-26T06:32:32.114Z",
        invoice_due_date: "2024-11-26T06:32:32.114Z",
        invoice_tds_flag: true,
        invoice_tds_id: 0,
        invoice_customer_notes: "string",
        invoice_terms_and_conditions: "string",
        invoice_sub_total: "105000.00",
        invoice_discount: "10500.00",
        invoice_total: "111510.00",
        invoice_status: true,
        created_by: "1",
        created_date: "2024-11-26T06:32:32.114Z",
        modified_by: "1",
        modified_date: "2024-11-26T06:32:32.114Z",
        delete_status: true,
      },
      {
        customer_id: "",
        company_id: 1,
        invoice_no: "inv-101",
        invoice_date: "2024-11-26T06:32:32.114Z",
        invoice_due_date: "2024-11-26T06:32:32.114Z",
        invoice_tds_flag: true,
        invoice_tds_id: 0,
        invoice_customer_notes: "string",
        invoice_terms_and_conditions: "string",
        invoice_sub_total: 0,
        invoice_discount: 0,
        invoice_total: 0,
        invoice_status: true,
        created_by: "1",
        created_date: "2024-11-26T06:32:32.114Z",
        modified_by: "1",
        modified_date: "2024-11-26T06:32:32.114Z",
        delete_status: true,
      },
      {
        debitAccountId: "1",
        creditAccountId: "1",
        amount: "2000",
        description: "Auto Created by invoice Creation",
        createdBy: "1",
      },
      {
        customer_id: "",
        company_id: 1,
        invoice_no: "inv-101",
        invoice_date: "2024-11-26T06:32:32.114Z",
        invoice_due_date: "2024-11-26T06:32:32.114Z",
        invoice_tds_flag: true,
        invoice_tds_id: 0,
        invoice_customer_notes: "string",
        invoice_terms_and_conditions: "string",
        invoice_sub_total: 0,
        invoice_discount: 0,
        invoice_total: 0,
        invoice_status: true,
        created_by: "1",
        created_date: "2024-11-26T06:32:32.114Z",
        modified_by: "1",
        modified_date: "2024-11-26T06:32:32.114Z",
        delete_status: true,
      },
    ],
  };
  const storage = JSON.parse(localStorage.getItem("Storage"));
  if (storage) {
    return storage;
  } else {
    setStorage(data);
    return data;
  }
}
export function setStorage(state) {
  localStorage.setItem("Storage", JSON.stringify(state));
}
