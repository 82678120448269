import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import Select from "react-select";
import { easing } from "@mui/material";

const GroupForm = () => {
  const { companyId, option, groupId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${groupId}`;
  const saverecord = "/api/v2/accounting/addaccountcore";
  const updaterecord = "/api/v2/accounting/updateaccountcore/" + groupId;
  const attachment_Prefix = "Journal_";
  let storage = getStorage();
  const store = useStore();

  const init = {
    acc_core_name: "",
    parent_id: { label: "Please Choose", value: "" },
    type_id: "",
  };
  const initProps = {
    add: true,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    acc_core_name: {},
    type_id: {
      type_id_list: [
        { label: "Please Choose Type", value: "" },
        { label: "Asset", value: 1 },
        { label: "Liabilities", value: 2 },
        { label: "Income", value: 3 },
        { label: "Expense", value: 4 },
      ],
    },
    parent_id: {
      parent_id_list: [
        { label: "Please Choose", value: "" },
        { label: "Parent", value: 0 },
      ],
    },
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  useEffect(() => {
    const localprops = getlocal("properties")?.AccountGroups || {};
    HandleMultiPropsChange(localprops);
    LoadParentGroup().then(() => {
      if (groupId !== undefined) {
        store.startLoading();
        if(option === "view"){
          HandleMultiPropsChange({
            acc_core_name: { readonly: true  },
            parent_id: {readonly: true},
            type_id: {readonly: true,hidden:false},
          });
        }
        else {
          HandleMultiPropsChange({
            acc_core_name: { mandatory: true },
            parent_id: { mandatory: true },
            type_id: {readonly: true,hidden:false},
          });
        }
        GetRecords("/api/v2/accounting/accountcorebyid/" + groupId).then(
          (res) => {
            console.log("getchartsofaccounts", res);
            if (res.success) {
              res = { ...res.record[0] };

              res.parent_id = props.parent_id.parent_id_list.filter(
                (item) => item.value === res.parent_id
              );

              handleMultiFieldChange({
                acc_core_name: res.acc_core_name,
                parent_id: res.parent_id[0],
                type_id: res.type_id,
              });
              HandleMultiPropsChange({
                type_id: { hidden: res.parent_id.value == 0 ? false : true },
              });
              store.stopLoading();
             
            }
          }
        );
      } else {
        store.setheader("CREATE NEW Company");

        HandleMultiPropsChange({
          acc_core_name: { mandatory: true },
          parent_id: { mandatory: true },
          type_id: { hidden: true },
        });
      }
    });
  }, [companyId, option, groupId]);

  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // Check for the mandatory Fields
  function CheckMandatory(Record = Record) {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // Update the Record
  function update() {
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.parent_id = savedata.parent_id?.value.toString();
    console.log("Submiting Updatedata", savedata);
    if (CheckMandatory(savedata)) {
      UpdateRecord(updaterecord, savedata).then((res) => {
        console.log("Resonce of UpdateRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("User Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
            " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  // Create the Record
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.parent_id = savedata.parent_id?.value.toString();
    console.log("Submiting savedata", savedata);
    if (CheckMandatory(savedata)) {
      saveRecord(saverecord, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("User Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
            " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  // Handle States Changes
  /**
   * Updates a specific field in the props state.
   * @param {string} fieldName
   * @param {string} value
   */
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // Handle Props Changes
  /**
   * Updates a specific field in the props state.
   * @param {string} fieldName - The field to update. Should be in allowedFields.
   * @param {Object} value - The new properties to merge.
   * @param {boolean} value.hidden - The new properties to merge.
   * @param {boolean} value.mandatory - The new properties to merge.
   * @param {boolean} value.readonly - The new properties to merge.
   */
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      // Create a shallow copy of the specific field
      let oldprop = { ...prev[fieldName] };

      // Merge new properties into the copied field
      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }

      // Return a new state object with the updated field
      return { ...prev, [fieldName]: oldprop };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);
      return { ...prev };
    });
  }

  // Handle Change in the fields
  function HandleParent(event) {
    console.log("getallaccountcore", event);

    if (event.value == "0") {
      handleFieldChange("type_id", "");
      handleFieldChange("parent_id", event);
      HandlePropsChange("type_id", { hidden: false, mandatory: true });
    } else {
      HandlePropsChange("type_id", { hidden: true, mandatory: false });
      handleFieldChange("parent_id", event);
      handleFieldChange("type_id", event.type_id);
    }
  }

  // *Load Selected Fields
  function LoadParentGroup(
    fieldname = "account_core_name",
    search = "",
    sort = "asc",
    page = 1,
    pagesize = 100
  ) {
    return GetRecords(
      `/api/v2/accounting/getallaccountcore?fieldname=${fieldname}&search=${search}&sort=${sort}&page=${page}&pagesize=${pagesize}`
    ).then((account) => {
      if (account.success) {
        let parent_id_list = initProps.parent_id.parent_id_list;
        account.record.list.map((item) => {
          parent_id_list.push({
            label: item.account_core_name,
            value: item.id,
            type_id: item.type_id,
          });
        });
        HandlePropsChange("parent", { parent_id_list });
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Group Form"}>
      <FormView
        actions={[
          {
            Name: "Back",
            onclick: () => {
              navigate(-1);
            },
            visible: true,
          },
          {
            Name: "Submit",
            onclick: Submit,
            type: "primary",
            visible: groupId == undefined,
          },
          {
            Name: "Update",
            onclick: update,
            type: "primary",
            visible: option === "edit" && groupId != undefined,
          },
        ]}
      >
        <div className="grid grid-cols-1 max-sm:grid-cols-1 gap-3 w-[80%]">
          <div // acc_core_name
            className={
              "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.acc_core_name.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Group Name
              {props.acc_core_name.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                (props.acc_core_name.mandatory && props.acc_core_name.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"acc_core_name"}
              type="text"
              value={Record.acc_core_name}
              disabled={props.acc_core_name.readonly}
              onChange={(event) => {
                handleFieldChange("acc_core_name", event.target.value);
              }}
            />
          </div>
          <div // parent_id
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.parent_id.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Parent Group
              {props.parent_id.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <Select
              className={
                " w-full  border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.parent_id.mandatory && props.parent_id.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"parent_id"}
              onInputChange={(e) => {
                LoadParentGroup("account_core_name", e);
              }}
              onChange={(e) => {
                HandleParent(e);
              }}
              options={props.parent_id.parent_id_list}
              value={Record.parent_id}
              placeholder="Search..."
              isDisabled={props.parent_id.readonly}
            />
          </div>
          <div // type_id
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.type_id.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Type
              {props.type_id.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"type_id"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.type_id.mandatory && props.type_id.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("type_id", event.target.value);
              }}
              value={Record.type_id}
              disabled={props.type_id.readonly}
            >
              {props.type_id.type_id_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </FormView>
    </AccountsLayout>
  );
};

export default GroupForm;
