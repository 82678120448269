import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetRecords,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";

const SalesForm = () => {
  const { option, id } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  const init = {
    salesNumber: "",
    customerName: "",
    date: "",
    Transaction: [{}],
    subTotal: 0,

    discountPercentage: 0,
    discountType: "%",
    discountValue: 0,

    GSTType: "",
    taxing: "",
    taxValue: 0,

    Total: 0,
    notes: "",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    salesNumber: {},
    customerName: {
      customerName_list: [
        { name: "Aditya Enterprises", type: "Debit" },
        { name: "Agarwal Fashion", type: "Credit" },
        { name: "A.K. Trading", type: "Debit" },
        { name: "Amman Agency", type: "Credit" },
      ],
    },
    date: {},
    Transaction: {
      particulars_list: [{ name: "Trade Mark" }, { name: "Reimbursement" }],
    },
    subTotal: {},
    discountPercentage: {},
    discountType: {
      discountType_list: [{ name: "%" }, { name: "₹" }],
    },
    discountValue: {},
    GSTType: {},
    taxing: {
      taxing_list: [{ name: "18% GST Sales", value: 18 }],
    },
    taxValue: {},
    Total: {},
    notes: {},

    type: {},
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  useEffect(() => {
    const localprops = getlocal("properties")?.companies || {};

    HandleMultiPropsChange(localprops);

    // console.log("Params",param);
    if (id !== undefined) {
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      // if (option === "view") {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: true },
      //     company_name: { readonly: true },
      //     company_address_line1: { readonly: true },
      //     company_address_line2: { readonly: true },
      //     company_address_city: { readonly: true },
      //     company_address_pincode: { readonly: true },
      //     company_address_state: { readonly: true },
      //     company_phone_alternative: { readonly: true },
      //     company_website_url: { readonly: true },
      //     company_phone: { readonly: true },
      //     company_email: { readonly: true },
      //     company_status: { readonly: true },
      //   });
      // } else {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: false },
      //     company_name: { readonly: false },
      //     company_address_line1: { readonly: false },
      //     company_address_line2: { readonly: false },
      //     company_address_city: { readonly: false },
      //     company_address_pincode: { readonly: false },
      //     company_address_state: { readonly: false },
      //     company_phone_alternative: { readonly: false },
      //     company_website_url: { readonly: false },
      //     company_phone: { readonly: false },
      //     company_email: { readonly: false },
      //     company_status: { readonly: false },
      //   });
      // }
      // GetRecords(getrecord).then((res) => {
      //   console.log("Companies Data - ", res, res.record);
      //   if (res.success) {
      //     handleMultiFieldChange(res.record);
      //   } else if (res.code === 401) {
      //     store.logout();
      //     store.navTo("/login");
      //   }
      // });
    } else {
      store.setheader("CREATE NEW Company");
      // props for the Mandatory field
      HandleMultiPropsChange({
        // voucher: { mandatory: true },
        // type: { hidden: true, readonly: true },
        // date: { hidden: true },
        // group: { hidden: true },
        // // journal_Number: { readonly: true },
        // reference_Number: { hidden: true },
        // notes: { hidden: true },
        // attachment: { hidden: true },
        // journal_Type: { hidden: true },
        // amount: { hidden: true },
        // currency: { hidden: true, readonly: true },
      });
    }
  }, [option, id]);

  useEffect(() => {
    console.log("Record State change -> ", Record);
  }, [Record]);

  // useEffect(() => {
  //   console.log("Props State change -> ", props);
  // }, [props]);

  function update() {}

  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();
      localStorage.setItem("journal_Number", Record.journal_Number + 1);

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }
  function HandleCustomer(params) {
    // let customer_list = props.customer.customer_list.filter(
    //   (item) => item.name === params
    // );
    // HandlePropsChange("type", { type: customer_list[0].type });
  }
  function TotalCalculation(Transaction = Record.Transaction) {
    let subTotal = 0;
    let discountValue = 0;
    let taxValue = 0;
    let Total = 0;
    let discountPercentage = Number(Record.discountPercentage);
    let taxing = Number(Record.taxing);

    Transaction.map((item) => {
      if (item.Amount !== "" && item.Amount !== undefined) {
        subTotal += Number(item.Amount);
      }
    });

    discountValue = (discountPercentage / 100) * subTotal;
    taxValue = (taxing / 100) * (subTotal - discountValue);
    Total = subTotal + taxValue - discountValue;
    handleFieldChange("subTotal", subTotal.toFixed(2));
    handleFieldChange("discountValue", discountValue.toFixed(2));
    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange("Total", Total.toFixed(2));
  }

  function HandleDiscountValue(discountPercentage) {
    let discountValue = 0;
    let taxing = Number(Record.taxing);
    let subTotal = Number(Record.subTotal);

    if (Record.discountType === "%") {
      discountValue = (discountPercentage / 100) * subTotal;
    } else if (Record.discountType === "₹") {
      discountValue = discountPercentage;
    }
    let taxValue = (taxing / 100) * (subTotal - discountValue);
    handleFieldChange("discountValue", discountValue.toFixed(2));
    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }
  function HandleDiscountType(discountType) {
    let discountValue = 0;
    let discountPercentage = Number(Record.discountPercentage);
    let subTotal = Number(Record.subTotal);
    let taxing = Number(Record.taxing);

    if (discountType === "%") {
      discountValue = (discountPercentage / 100) * subTotal;
    } else if (discountType === "₹") {
      discountValue = discountPercentage;
    } else {
      discountValue = 0;
    }
    let taxValue = (taxing / 100) * (subTotal - discountValue);

    console.log("HandleDiscountType", discountValue);

    handleFieldChange("discountValue", discountValue?.toFixed(2));
    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }
  function HandleTaxValue(taxing) {
    let subTotal = Number(Record.subTotal);
    let discountValue = Number(Record.discountValue);
    taxing = Number(taxing);

    let taxValue = (taxing / 100) * (subTotal - discountValue);

    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }

  return (
    <AccountsLayout HeaderTitle={"Sales Form"}>
      <FormView
        actions={[
          {
            Name: "Back",
            onclick: () => {
              navigate(-1);
            },
            visible: true,
          },
          {
            Name: "Save and Publish",
            onclick: Submit,
            type: "primary",
            visible: id === undefined && props.add,
          },
        ]}
      >
        <div className="grid grid-cols-1 max-sm:grid-cols-1 gap-3 w-[80%]">
          <div // customerName
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.customerName.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Customer Name
              {props.customerName.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"customerName"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.customerName.mandatory && props.customerName.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("customerName", event.target.value);
                HandleCustomer(event.target.value);
                TotalCalculation();
              }}
              value={Record.customerName}
              disabled={props.customerName.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.customerName.customerName_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // date
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.date.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Date
              {props.date.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.date.mandatory && props.date.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"date"}
              type="date"
              value={Record.date.split("T")[0]}
              disabled={props.date.readonly}
              onChange={(event) => {
                handleFieldChange("date", event.target.value + "T00:00:00Z");
              }}
            />
          </div>
          <div // Transaction
            className={
              " text-[14px] w-full h-full px-3 mt-4 flex-col items-center gap-3 flex bg-Alice_Blue p-2 rounded-lg relative "
              // +(props.amount.hidden ? " hidden " : " flex ")
            }
          >
            <span className="absolute -top-2 -right-10">
              <button
                className={PrimaryBTN}
                onClick={() => {
                  let Transaction = Record.Transaction;
                  Transaction.push({});
                  console.log("let Transaction", Transaction);

                  handleFieldChange("Transaction", Transaction);
                }}
              >
                Add New Item
              </button>
            </span>
            <div className="flex justify-around w-full">
              <div className="text-[18px] font-bold w-[50%] ">Particulars</div>
              <div className="text-[18px] font-bold w-[50%] ">Quantity</div>
              <div className="text-[18px] font-bold w-[50%] ">Rate</div>
              <div className="text-[18px] font-bold w-[50%] ">Amount</div>
            </div>
            {Record.Transaction.map((item, index) => (
              <div className="flex justify-around w-full" key={index}>
                <div // Transaction.Group
                  className={
                    "flex text-[14px] w-full h-full  flex-row items-center gap-3 "
                    // +(props.Transaction.hidden ? " hidden " : " flex ")
                  }
                >
                  <select
                    id={"Transaction"}
                    className={
                      " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                      (props.Transaction.mandatory && props.Transaction.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    onChange={(event) => {
                      let Transaction = Record.Transaction;
                      Transaction[index].Group = event.target.value;
                      handleFieldChange("Transaction", Transaction);
                    }}
                    value={item.Group}
                    disabled={props.Transaction.readonly}
                  >
                    <option value={""}>Please Choose</option>
                    {props.Transaction.particulars_list.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div // Transaction.Quantity
                  className={
                    "text-[14px] w-full h-full px-3 flex-row items-center gap-3 "
                    // +(props.Transaction.hidden ? " hidden " : " flex ")
                  }
                >
                  <input
                    className={
                      "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                      (props.Transaction.mandatory && props.Transaction.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"Transaction"}
                    type="text"
                    value={item.Quantity}
                    disabled={item.Group === "" || item.Group === undefined}
                    onChange={(event) => {
                      let Transaction = Record.Transaction;
                      Transaction[index].Quantity = event.target.value;
                      handleFieldChange("Transaction", Transaction);
                      TotalCalculation();
                    }}
                  />
                </div>
                <div // Transaction.Rate
                  className={
                    "text-[14px] w-full h-full px-3 flex-row items-center gap-3 "
                    // +(props.Transaction.hidden ? " hidden " : " flex ")
                  }
                >
                  <input
                    className={
                      "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                      (props.Transaction.mandatory && props.Transaction.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"Transaction"}
                    type="text"
                    value={item.Rate}
                    disabled={item.Group === "" || item.Group === undefined}
                    onChange={(event) => {
                      let Transaction = Record.Transaction;
                      Transaction[index].Rate = event.target.value;
                      handleFieldChange("Transaction", Transaction);
                      TotalCalculation();
                    }}
                  />
                </div>
                <div // Transaction.Amount
                  className={
                    "text-[14px] w-full h-full px-3 flex-row items-center gap-3 "
                    // +(props.Transaction.hidden ? " hidden " : " flex ")
                  }
                >
                  <input
                    className={
                      "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                      (props.Transaction.mandatory && props.Transaction.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"Transaction"}
                    type="number"
                    value={item.Amount}
                    disabled={item.Group === "" || item.Group === undefined}
                    onChange={(event) => {
                      let Transaction = Record.Transaction;
                      Transaction[index].Amount = event.target.value;
                      handleFieldChange("Transaction", Transaction);
                      TotalCalculation();
                    }}
                  />
                </div>
                <span
                  onClick={() => {
                    let Transaction = Record.Transaction;
                    Transaction = Transaction.filter(
                      (Transactionitem, Transactionindex) =>
                        Transactionindex != index
                    );
                    handleFieldChange("Transaction", Transaction);
                    TotalCalculation(Transaction);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>
              </div>
            ))}
          </div>
          <div // Total
            className={
              " text-[14px] w-full h-full py-2 mt-4  grid grid-cols-4 rounded-lg gap-5 items-center" +
              (props.Total.hidden ? " hidden " : " flex ")
            }
          >
            {/* Sub Total For the Sales record */}
            <span className="col-span-1"></span>
            <div className="px-3 col-span-2 font-bold text-[16px]">
              {"Sub Total"}
            </div>
            <div className=" font-bold">{Record.subTotal}</div>

            {/* Discount for the sales Total */}
            <span className="col-span-1"></span>
            <div className="px-3 col-span-1 font-bold text-[14px]">
              {"Discounts"}
            </div>
            <div // discountValue
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 col-span-1 " +
                (props.discountValue.hidden ? " hidden " : " flex ")
              }
            >
              <input
                className={
                  " border rounded-lg w-[60%] font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                  (props.discountPercentage.mandatory &&
                  props.discountPercentage.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"discountPercentage"}
                type="number"
                value={Record.discountPercentage}
                disabled={props.discountPercentage.readonly}
                onChange={(event) => {
                  handleFieldChange("discountPercentage", event.target.value);
                  HandleDiscountValue(event.target.value);
                }}
              />
              <select
                id={"discount"}
                className={
                  " w-[40%] border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                  (props.discountType.mandatory && props.discountType.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("discountType", event.target.value);
                  HandleDiscountType(event.target.value);
                }}
                value={Record.discountType}
                disabled={props.discountType.readonly}
              >
                <option value={""}>Please Choose</option>
                {props.discountType.discountType_list.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" font-bold">
              {(Record.discountValue == "0" || Record.discountValue == "0.00"
                ? ""
                : "-") + Record.discountValue}
            </div>

            {/* Taxing for the sales Total */}
            <span className="col-span-1"></span>
            {/* GSTType  */}
            <div className="px-3 col-span-1 font-bold text-[14px] flex gap-3">
              <span className="flex items-center gap-1">
                <input
                  type="radio"
                  className="accent-primary w-5 h-5"
                  checked={Record.GSTType === "TAX"}
                  onClick={() => {
                    handleFieldChange("GSTType", "TAX");
                  }}
                />
                TAX
              </span>
              <span className="flex items-center gap-1">
                <input
                  type="radio"
                  className="accent-primary w-5 h-5"
                  checked={Record.GSTType === "TCS"}
                  onClick={() => {
                    handleFieldChange("GSTType", "TCS");
                  }}
                />
                TCS
              </span>
            </div>
            <div // Taxing
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 col-span-1 " +
                (props.taxing.hidden ? " hidden " : " flex ")
              }
            >
              <select
                id={"Taxing"}
                className={
                  " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                  (props.taxing.mandatory && props.taxing.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("taxing", event.target.value);
                  HandleTaxValue(event.target.value);
                }}
                value={Record.taxing}
                disabled={props.taxing.readonly}
              >
                <option value={""}>Please Choose</option>
                {props.taxing.taxing_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" font-bold">{Record.taxValue}</div>

            {/* Total Amount for the sales Order */}
            <span className="col-span-1"></span>
            <div className="px-3 col-span-2 font-bold text-[18px]">
              {"Total (₹)"}
            </div>
            <div className=" font-bold">{Record.Total}</div>
          </div>
          <div // notes
            className={
              "flex text-[14px] w-full h-full px-3 mt-4 flex-col items-start gap-3  " +
              (props.notes.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Notes
              {props.notes.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <textarea
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
                (props.notes.mandatory && props.notes.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"notes"}
              value={Record.notes}
              disabled={props.notes.readonly}
              onChange={(event) => {
                handleFieldChange("notes", event.target.value);
              }}
            />
          </div>
        </div>
      </FormView>
    </AccountsLayout>
  );
};

export default SalesForm;
