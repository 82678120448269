import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal, getStorage } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  URL_Account_Charts_List,
  URL_Account_Group_List,
  URL_Account_Journal_Create,
} from "../../../Constancs/apiconst";
import { IoIosCloseCircleOutline } from "react-icons/io";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

// function FormStage({ Stages = [] }) {
//   return (
//     <div className="flex items-center justify-center h-[38px] gap-3">
//       {Stages.map((stage, index) => (
//         <div key={index} className=" flex gap-1 items-center">
//           <span
//             className={
//               " border p-1 rounded-full text-[14px] " +
//               (stage.selected
//                 ? " bg-Ocean_Blue text-secondary "
//                 : stage.completed
//                 ? " bg-[#3ec95c] text-secondary "
//                 : " ")
//             }
//           >
//             {stage.completed ? <TiTick /> : stage.icon}
//           </span>
//           <h3 className="text-[14px] pr-3">{stage.Name}</h3>
//           {index < Stages.length - 1 ? <FaArrowRight /> : ""}
//         </div>
//       ))}
//     </div>
//   );
// }

const JournalsForm = () => {
  const { option, id, companyId, journalId } =
    useParams();
  const navigate = useNavigate();
  //const getrecord = `/api/v2/journalentry/journal/${id}/${transactiontypeid}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  const attachment_url_Prefix = "Journal";
  let storage = getStorage();
  // *initial values
  const init = {
    proj_id: 0,
    cust_id: 0,
    journal_no: "JNL/24-25/0176",
    debitAccountId: "",
    creditAccountId: "",
    date: "",
    currency: "inr",
    amount: "",
    description: "Notes: ",
    attachment_url: [],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    journal_no: {},
    debitAccountId: {
      debitAccountId_list: [{ value: "", label: "Please Choose" }],
    },
    creditAccountId: {
      creditAccountId_list: [{ value: "", label: "Please Choose" }],
    },
    date: {},
    reference_Number: {},
    currency: {
      currency_list: [{ label: "INR-Indian Currency", value: "inr" }],
    },
    amount: {},
    description: {},
    attachment_url: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    store.setmodule("journals", init);
    const localprops = getlocal("properties")?.journals || {};
    HandleMultiPropsChange(localprops);
    GetGroupList();

    console.log("journal Data - >",journalId);
    if (journalId !== undefined) {
      let journal = storage.journal;
      handleMultiFieldChange(journal[id]);
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      if (option === "view") {
        HandleMultiPropsChange({
          debitAccountId: { readonly: true },
          creditAccountId: { readonly: true },
          date: { readonly: true },
          amount: { readonly: true },
          description: { readonly: true },
         currency: { readonly: true }
        });
      } else {
        HandleMultiPropsChange({
          debitAccountId: { mandatory: true },
          creditAccountId: { mandatory: true },
          date: { mandatory: true },
          amount: { mandatory: true },
        });
      }
      GetRecords(
        `/api/v2/journalentry/journal/${journalId}`
      ).then((res) => {
        if (res.success) {
          res = res?.record?.journals || {};
          console.log("journal Data - ", res);
          handleMultiFieldChange({
            journal_no: res.journal_no,
            debitAccountId: res.debit_accid,
            creditAccountId: res.credit_accid,
            date: res.journal_date,
            amount: res.journal_total,
            description: res.journal_customer_notes,
          });
        } else if (res.code === 401) {
          ErrorAlert(" Error Found Please contact the Admin");
        }
        //         else if (res.code === 401) {
        //   store.logout();
        //   store.navTo("/login");
        // }
      });
    } else {
      store.setheader("CREATE NEW Company");
      GetRecords("/api/v2/journalentry/generate-journal-id/" + companyId).then(
        (res) => {
          if (res.success) {
            res = res.record;
            handleFieldChange("journal_no", res);
            console.log("journal_no", res);
          }
        }
      );
      HandleMultiPropsChange({
        debitAccountId: { mandatory: true },
        creditAccountId: { mandatory: true },
        date: { mandatory: true },
        amount: { mandatory: true },
      });
    }
  }, [option, id]);

  function GetGroupList() {
    getList({ listUrl: URL_Account_Charts_List+companyId, count: 1000 }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("URL_Account_Charts_List", res);

        HandleMultiPropsChange({
          debitAccountId: {
            debitAccountId_list: [
              ...initProps.debitAccountId.debitAccountId_list,
              ...res,
            ],
          },
          creditAccountId: {
            creditAccountId_list: [
              ...initProps.creditAccountId.creditAccountId_list,
              ...res,
            ],
          },
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  // useEffect(() => {
  //   console.log("Record State change -> ", Record);
  //   console.log("Props State change -> ", props);
  // }, [Record]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.createdBy = store.getuser().id;
    savedata.company_id = companyId;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();

      saveRecord(URL_Account_Journal_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Journal Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment_url = Record.attachment_url || [];
    UploadFile(attachment_url_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment_url", [...attachment_url, res.data]);
      }
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Journal Form"}>
      <div className="flex flex-col h-full text-[12px] gap-1">
        <FormHeader
          State={
            option == "view" ? "View" : option == "edit" ? "Edit" : "Draft"
          }
          FormName="Journal"
          FormNumber={Record.journal_no}
          Actions={[
            {
              ButtonName: "Confirm",
              type: "primary",
              onClick: Submit,
              visible: journalId == undefined,
            },
            {
              ButtonName: "Cancel",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        {/* <FormStage Stages={props.Stages.Stages_list} /> */}
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-5">
            <div // debitAccountId
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.debitAccountId.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                From Account
                {props.debitAccountId.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"debitAccountId"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.debitAccountId.mandatory && props.debitAccountId.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("debitAccountId", event.target.value);
                }}
                value={Record.debitAccountId}
                disabled={props.debitAccountId.readonly}
              >
                {props.debitAccountId.debitAccountId_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Date
                {props.date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.date.mandatory && props.date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"date"}
                type="date"
                value={Record.date.split("T")[0]}
                disabled={props.date.readonly}
                onChange={(event) => {
                  handleFieldChange("date", event.target.value + "T00:00:00Z");
                }}
              />
            </div>
            <div // creditAccountId
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.creditAccountId.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                To Account
                {props.creditAccountId.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"creditAccountId"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.creditAccountId.mandatory && props.creditAccountId.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("creditAccountId", event.target.value);
                }}
                value={Record.creditAccountId}
                disabled={props.creditAccountId.readonly}
              >
                {props.creditAccountId.creditAccountId_list.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>
            <div // currency
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.currency.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                currency
                {props.currency.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"currency"}
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.currency.mandatory && props.currency.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("currency", event.target.value);
                }}
                value={Record.currency}
                disabled={props.currency.readonly}
              >
                {props.currency.currency_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div // amount
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.amount.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Amount
                {props.amount.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.amount.mandatory && props.amount.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"amount"}
                type="text"
                value={Record.amount}
                disabled={props.amount.readonly}
                onChange={(event) => {
                  if (Number(event.target.value).toString() !== "NaN")
                    handleFieldChange("amount", event.target.value);
                }}
              />
            </div>
            <div // attachment_urls
              className={
                " text-[14px] w-[95%] px-5 flex-col gap-1 flex py-2 relative "
              }
            >
              <h3 className="text-[14px] ">Attachments</h3>
              <div // attachment_url
                className={
                  "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                  (props.attachment_url.hidden ? " hidden " : " flex ")
                }
              >
                {option != "view" && (
                  <button
                    className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                    onClick={() => {
                      document.getElementById("FileInput").click();
                    }}
                  >
                    <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                    Upload File
                  </button>
                )}
                {Record.attachment_url.map((attached, index) => (
                  <button
                    key={index}
                    className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                    target="_blank"
                  >
                    <FaRegEye
                      className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                      onClick={() => {
                        store.navtotab(
                          baseurl + "/files/preview?key=" + attached
                        );
                      }}
                    />
                    {attached.replaceAll("Journal/", "")}
                    {journalId === undefined && (
                      <IoIosCloseCircleOutline
                        className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                        onClick={() => {
                          let attachment_url = Record.attachment_url || [];
                          attachment_url = attachment_url.filter(
                            (item, itemindex) => itemindex != index
                          );
                          handleFieldChange("attachment_url", [
                            ...attachment_url,
                          ]);
                        }}
                      />
                    )}
                  </button>
                ))}
                <input
                  type="file"
                  onChange={HandleAttachement}
                  id="FileInput"
                  className="hidden"
                />
              </div>
            </div>
            <div // description
              className={
                "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1 col-span-2 " +
                (props.description.hidden ? " hidden " : " flex ")
              }
            >
              <textarea
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                  (props.description.mandatory && props.description.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"description"}
                value={Record.description}
                disabled={props.description.readonly}
                onChange={(event) => {
                  if (event.target.value.length > 7) {
                    handleFieldChange("description", event.target.value);
                  } else {
                    handleFieldChange("description", "Notes: ");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default JournalsForm;
