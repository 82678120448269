import axios from "axios";
import { getlocal } from "./localstorage";
import { Slide, toast } from "react-toastify";

//export const baseurl = `http://localhost:4040`;
// export const baseurl = `https://absolute-uat-backend.binarywavesolutions.com`;
export const baseurl = `https://absolute-backend.binarywavesolutions.com`;

 //export const baseurl = `https://localhost:7099`;

// const urlprifix = "V1";
export const URLPRIFIX = "demo";

export function CheckMandatory(module, store) {
  const props = store.getallprops()[module];
  const record = store.getrecord();
  let out = true;
  console.log("Props", props);
  let Fields = "";
  for (let obj in props) {
    // console.log("CheckMandatory", obj, props[obj], props[obj].mandatory);
    // console.log("CheckMandatory", obj,typeof props[obj]);

    if (typeof props[obj] === "object") {
      if (props[obj].mandatory) {
        if (record[obj] == "" || record[obj] == " ") {
          store.setprops(obj, { mandatory: true, show: true });
          out = false;
        } else {
          store.setprops(obj, { mandatory: true, show: false });
        }
      }
    }
  }
  out === false && ErrorAlert("Please fill the Mandatory Fields.");
  out === false && store.stopLoading();
  return out;
}
export function login(store) {
  axios
    .post(`${baseurl}/users/login`, store.getrecord())
    .then((res) => {
      res = res.data;
      console.log("Res, ", res);
      let state = store.state;
      state.system.login = true;
      state.system.token = res.token;
      state.system.user = {
        name: res.userName,
        id: res.userId,
        role: res.role_id,
        company: 1,
      };
      // store.login();
      // store.setsystemvalue("token", res.token);
      // store.setsystemvalue("user", { name: res.userName, id: res.userId });
      if (res.access_control && res.access_control !== "") {
        let props = {};
        JSON.parse(res.access_control).map((item) => {
          if (item.name === "categories") {
            props["categories"] = item.permissions;
            props["category"] = item.permissions;
          } else {
            props[item.name] = item.permissions;
            props[item.name.substring(0, item.name.length - 1)] =
              item.permissions;
          }
        });
        state.properties = props;
      }
      console.log("state for login", state);

      store.setstate(state);
      store.navTo("/demo/dashboard");
    })
    .catch((res) => {
      // console.log("REsponce", res);
      if (res.code === "ERR_NETWORK") {
        ErrorAlert("network connection failed. Please contact the Admin");
      } else {
        res = res.response.data;
        // console.log("Res, ", res);
        if (res.errorMessage === "Username or password did not match.") {
          ErrorAlert("Password Is Wrong.");
        } else if (res.errorMessage === "Invalid credentials") {
          ErrorAlert("UserName Is Un-Avaliable.");
        }
      }
    });
}
export function GetListDropdown({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
  store,
}) {
  let token = getlocal("system").token;
  // console.log("token", listUrl, field, search, sort, page, count, store);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&pagesize=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  console.log("GetListDropdown", url);

  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      res = res.data.list;
      // // console.log("GetListDropdown list", res);
      res = res.map((item) => {
        return { ...item, value: item.id, label: item[field] };
      });
      console.log("GetListDropdown list" + listUrl + " - ", res);

      store.setprops(field + "_list", res);
    })
    .catch((res) => {
      // console.log("Error REsponce", url, res);
      if (res.code === "ERR_NETWORK") {
        // store.logout();
      }
    });
}
export function getList({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
  store,
}) {
  let token = getlocal("system").token;
  console.log("getList", listUrl, field, search, sort, page, count);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&pagesize=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      // console.log("GetList responce - ", res);
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return { success: true, errorResponce: res };
      }
    })
    .catch((res) => {
      console.log("Error REsponce", res);
      if (res.code === "ERR_NETWORK") {
        // store.logout();
      }
      return { success: true, errormessage: res };
    });
}
export function GetListview({
  listUrl = "",
  field = "",
  search = "",
  sort = "",
  page = 1,
  count = 10,
  store,
}) {
  let token = getlocal("system").token;
  // console.log("token", listUrl, field, search, sort, page, count, store);
  let url = "";
  if (field !== "") url += "&fieldname=" + field;
  if (search !== "") url += "&search=" + search;
  if (sort !== "") url += "&sort=" + sort;
  if (page !== "") url += "&page=" + page;
  if (count !== "") url += "&count=" + count;
  url = url.substring(1, url.length);
  url = `${baseurl}${listUrl}?${url}`;

  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log("GetListview responce - ", res);
      res = res.data;

      let totalCount = res.totalCount / count;

      // console.log("totalCount value", totalCount % 1 == 0);
      if (totalCount % 1 == 0) {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount);
      } else {
        // console.log("totalCount", totalCount);
        totalCount = Math.floor(totalCount) + 1;
      }

      store.setvalue("totalpage", totalCount);
      store.setvalue("currentpage", page);
      // // console.log("Res, ", totalCount);

      store.setvalue("list", res.list);
      let head = Object.keys(res.list[0]);
      head.splice(5, 0, "ACTION");
      store.setvalue("header", head);
    })
    .catch((res) => {
      console.log("Error REsponce", res);
      // if (res.code === "ERR_BAD_REQUEST") {
      //   store.logout();
      // }
      // if (res.code === "ERR_NETWORK") {
      //   store.logout();
      // }
    });
}
export function GetRecords(url) {
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = `${baseurl}${url}`;
  return axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Get record Responsce - ", res);
      if (res.status === 200) {
        return { success: true, record: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return {
        success: false,
        errormessage: res,
        code: res.code === "ERR_BAD_REQUEST" ? 404 : 401,
      };
    });
}
export function DeleteRecord(url, record) {
  // console.log("Delete record - ", getlocal("system").token, url, record);
  let headers = {
    Authorization: `Bearer ${getlocal("system").token}`,
    "Content-Type": "application/json",
  };

  url = baseurl + url;

  return axios
    .delete(url, {
      headers: headers,
      data: record,
    })
    .then((res) => {
      // console.log(" Delete record Responsce - ", res.data.id);
      if (res.status === 200) {
        return { success: true, Id: res.data.id };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function UpdateRecord(url, record, header) {
  // console.log("Update record - ", getlocal("system").token, url, record);
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = baseurl + url;
  if (header) {
    headers = { "Content-Type": "multipart/form-data" };
  }
  console.log("UpdateRecord", url, record, header);
  return axios
    .put(url, record, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Update record Responsce - ", res.data);
      if (res.status === 200) {
        return { success: true, Id: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      console.log("catch responce - ", res);
      return { success: false, errormessage: res.response };
    });
}
export function saveRecord(url, record, header) {
  // console.log("Save record - ", url, record);
  let headers = { Authorization: `Bearer ${getlocal("system").token}` };

  url = baseurl + url;
  if (header) {
    headers = { "Content-Type": "multipart/form-data" };
  }
  return axios
    .post(url, record, {
      headers: headers,
    })
    .then((res) => {
      console.log(" Save record Responsce - ", res);
      if (res.status === 200) {
        return { success: true, Id: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function UploadFile(Prefix, file) {
  // console.log("Save record - ", Prefix, file);

  let headers = {
    Authorization: `Bearer ${getlocal("system").token}`,
    "Content-Type": "multipart/form-data",
  };

  let url = baseurl + "/files/UploadFiles?prefix=" + Prefix;
  // if (header) {
  //   headers = { "Content-Type": "multipart/form-data" };
  // }
  return axios
    .post(url, file, {
      headers: headers,
    })
    .then((res) => {
      // console.log(" File Save Responsce - ", res);
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function GetFile(filename) {
  // console.log("Save record - ", Prefix, file);

  let headers = {
    Authorization: `Bearer ${getlocal("system").token}`,
    "Content-Type": "multipart/form-data",
  };

  let url = baseurl + "/files/preview?key=" + filename;
  // if (header) {
  //   headers = { "Content-Type": "multipart/form-data" };
  // }
  return axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        return { success: false, errorResponce: res };
      }
    })
    .catch((res) => {
      // console.log("catch responce - ", res);
      return { success: false, errormessage: res };
    });
}
export function GetLitigation(key) {
  if (JSON.parse(localStorage.getItem("Nonlitigation"))) {
    return JSON.parse(localStorage.getItem("Nonlitigation"))[key];
  } else {
    SetLitigation({
      clients: [],
      categories: [
        { label: "Hotel", value: "1" },
        { label: "Film", value: "2" },
        { label: "Rental", value: "3" },
      ],
    });
  }
}
export function GetAllLitigation() {
  if (JSON.parse(localStorage.getItem("Nonlitigation"))) {
    return JSON.parse(localStorage.getItem("Nonlitigation"));
  } else {
    SetLitigation({
      clients: [],
      categories: [
        { label: "Hotel", value: "1" },
        { label: "Film", value: "2" },
        { label: "Rental", value: "3" },
      ],
    });
  }
}
export function PasswordNotification(data) {
  let url = baseurl + `/users/forgotpassword/${data}`;

  return axios
    .post(url)
    .then((res) => {
      console.log("Response:", res);
      if (res.status === 200) {
        return { success: true, data: res.data };
      } else {
        console.log("Unexpected response:", res);
      }
    })
    .catch((err) => {
      console.log("Request error:", err);
    });
}
export function SetLitigation(state) {
  localStorage.setItem("Nonlitigation", JSON.stringify(state));
}
export function Setlists(field, data) {
  let local = JSON.parse(localStorage.getItem("Nonlitigation"));
  // console.log("Local", local, field, data);
  localStorage.setItem(
    "Nonlitigation",
    JSON.stringify({ ...local, [field]: data })
  );
}

// Alerts Creator
export function SuccessAlert(Message) {
  toast.success(Message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
  });
}
export function WarningAlert(Message) {
  toast.warning(Message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
  });
}
export function ErrorAlert(Message) {
  toast.error(Message, {
    position: "top-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
  });
}

export function GetDateTimeFormat(date = new Date()) {
  function makeFormat(date) {
    return `${CheckDigitformat(date.getFullYear(), 4)}-${CheckDigitformat(
      date.getMonth() + 1,
      2
    )}-${CheckDigitformat(date.getDate(), 2)}T${CheckDigitformat(
      date.getHours(),
      2
    )}:${CheckDigitformat(date.getMinutes(), 2)}:${CheckDigitformat(
      date.getSeconds(),
      2
    )}`;
  }
  const DateTime = makeFormat(date);

  const MonthStartDate = makeFormat(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
  const MonthEndDate = makeFormat(
    new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );

  return {
    DateTime: DateTime.replace("T", " "),
    DBFormat: DateTime,
    MonthStartDate,
    MonthEndDate,
  };
}

const CheckDigitformat = (value, size) => {
  value += "";
  while (value.length + 1 <= size) {
    value = "0" + value;
    console.log("checkformat", value);
  }
  return value;
};

export function RupeeFormat(Amount = "") {
  let out = [];
  let negative = false;
  if (Amount.toString().includes("-")) {
    negative = true;
    Amount = Amount.toString().replace("-", "");
  } else {
    Amount = Amount.toString();
  }
  Amount = Amount.split(".");
  let decimal = Amount[1]?.substring(0, 2) || "00";
  let count = 3;
  Amount = Amount[0] || "0";
  Amount = Amount.split("") || [];
  out = [...Amount];
  for (let i = Amount.length - 1; i >= 0; i--) {
    if (count > 0) {
      count--;
    } else {
      count = 1;
      out.splice(i + 1, 0, ",");
    }
  }
  if (negative) {
    out = "-" + out.join("") + "." + decimal;
  } else {
    out = out.join("") + "." + decimal;
  }

  return out;
}
