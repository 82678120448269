import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import {
  baseurl,
  DeleteRecord,
  ErrorAlert,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import {
  MdCancel,
  MdDataSaverOff,
  MdOutlineCancel,
  MdOutlineDataSaverOff,
  MdOutlineFileUpload,
  MdOutlineFreeCancellation,
  MdOutlinePaid,
} from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";
import {
  FaArrowRight,
  FaPenNib,
  FaRegCalendarCheck,
  FaTruckLoading,
} from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { GiFinishLine } from "react-icons/gi";
import axios from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  URL_Account_Charts_List,
  URL_Account_Customer_List,
  URL_Account_Customer_Project_List,
  URL_Account_Expense_Approval,
  URL_Account_Expense_AwaitingApproval,
  URL_Account_Expense_By_ID,
  URL_Account_Expense_Create,
  URL_Account_Expense_List,
  URL_Account_Expense_paid,
  URL_Account_Expense_Reject,
  URL_Account_Expense_Update,
} from "../../../Constancs/apiconst";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed === true
                ? " bg-[#3ec95c] text-secondary "
                : stage.completed === false
                ? " bg-[#c93e3e] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const ExpenseForm = () => {
  const { companyId, option, expenseId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${expenseId}`;
  const attachment_url_Prefix = "Journal_";
  const store = useStore();
  const user = getlocal("system")?.user || {};

  // *initial values
  const init = {
    customer_id: 0,
    requested_by: 0,
    approved_by: 0,
    proj_id: 0,
    expense_no: "EXP/24-25/0010",
    expense_date: "",
    expense_customer_notes: "Notes: ",
    delivery_address: "",
    expense_terms_and_conditions: "Terms & Conditions: ",
    expense_total: 0,
    expense_status: true,
    expense_category_id: 9,
    tds_payable_id: 25,
    tds_payable_amount: 0,
    paid_through_acc_id: 15,
    attachment_url: [],
    items: [
      {
        charts_acc_id: 0,
        amount: 0,
        description: "",
      },
    ],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    stage: {},
    Stages: {
      Stages_list: [
        {
          Name: "Draft",
          icon: <FaPenNib />,
          selected: true,
        },
        {
          Name: "Awaiting Approval",
          icon: <FaRegCalendarCheck />,
          selected: false,
        },
        {
          Name: "Awaiting Payment",
          icon: <MdOutlinePaid />,
          selected: false,
        },
      ],
    },
    salesOrder: {
      salesOrder_list: [
        { value: "1", label: "Sales" },
        { value: "2", label: "TradeMark Sales" },
        { value: "3", label: "Agreements Sales" },
      ],
    },

    expense_no: {},
    delivery_address: {
      delivery_address_list: [{ value: "", label: "Please Choose" }],
    },
    proj_id: {
      proj_id_list: [{ value: "", label: "Please Choose" }],
    },
    customer_id: {
      customer_id_list: [{ value: "", label: "Please Choose" }],
    },
    requested_by: {
      requested_by_list: [{ value: "", label: "Please Choose" }],
    },
    approved_by: {
      hidden: true,
      approved_by_list: [{ value: "", label: "Please Choose" }],
    },
    paid_through_acc_id: {
      paid_through_acc_id_list: [{ value: "", label: "Please Choose" }],
      hidden: true,
    },
    delivery_address: {
      delivery_address_list: [{ value: "", label: "Please Choose" }],
    },
    expense_date: {},
    dueDate: {},
    tds_payable_id: {},
    tds_payable_amount: {},
    expense_category_id: {},

    items: {
      particulars_list: [
        {
          value: 0,
          label: "Please Select",
        },
      ],
      MandatoryItems: {},
    },

    expense_status: {},

    expense_total: {},
    expense_customer_notes: {},
    expense_terms_and_conditions: {},
    attachment_url: { url: "" },
    payment: {
      toacc: "",
    },
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    store.setmodule("Expenses", init);
    const localprops = getlocal("properties")?.Expenses || {};
    HandleMultiPropsChange(localprops);
    GetUserList();
    GetCustomerList();
    HandleByEmployee();
    GetPaidThoughtList();
    if (expenseId !== undefined) {
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      if (option === "view") {
        HandleMultiPropsChange({
          customer_id: { readonly: true },
          proj_id: { readonly: true },
          expense_date: { readonly: true },
          expense_customer_notes: { readonly: true },
          paid_through_acc_id: { readonly: true },
          delivery_address: { readonly: true },
          expense_terms_and_conditions: { readonly: true },
          expense_category_id: { readonly: true },
          items: { readonly: true },
          approved_by: { hidden: false },
        });
      } else {
        HandleMultiPropsChange({
          customer_id: { mandatory: true },
          proj_id: { mandatory: true },
          expense_date: { mandatory: true },
          expense_category_id: { readonly: true },
          approved_by: { hidden: false },
        });
      }
      GetRecords(URL_Account_Expense_By_ID + expenseId).then((res) => {
        store.startLoading();
        console.log("expenseId Data - ", res, res.record);
        if (res.success) {
          res = res.record;
          console.log("expenseId Data - ", res);
          handleMultiFieldChange({
            customer_id: res.expense.customer_id,
            proj_id: res.expense.proj_id,
            expense_no: res.expense.expense_no,
            expense_date: res.expense.expense_date,
            approved_by: res.expense.approved_by,
            requested_by: res.expense.requested_by,
            expense_customer_notes: res.expense.expense_customer_notes,
            delivery_address: res.expense.delivery_address,
            expense_terms_and_conditions:
              res.expense.expense_terms_and_conditions,
            expense_total: res.expense.expense_total,
            expense_status: res.expense.expense_status,
            expense_category_id: res.expense.expense_category_id,
            tds_payable_id: res.expense.tds_payable_id,
            tds_payable_amount: res.expense.tds_payable_amount,
            paid_through_acc_id: res.expense.paid_through_acc_id,
            attachment_url: res.expense.attachment_url || [],
            items: res.items || [],
          });

          GetCustomerList(res.expense.expense_category_id);
          GetProjectList(res.expense.customer_id);
          GetAddressList(res.expense.customer_id);
          if (res.expense.expense_category_id == 8) {
            HandleMultiPropsChange({
              customer_id: { hidden: false, mandatory: true },
              delivery_address: { hidden: false, mandatory: false },
              proj_id: { hidden: false, mandatory: true },
              paid_through_acc_id: { hidden: false, mandatory: true },

              requested_by: { hidden: true, readonly: false },
              approved_by: { hidden: true, readonly: false },
            });
          } else if (res.expense.expense_category_id == 9) {
            HandleMultiPropsChange({
              customer_id: { hidden: false, mandatory: true },
              delivery_address: { hidden: false, mandatory: false },
              proj_id: { hidden: false, mandatory: true },
              paid_through_acc_id: { hidden: true, mandatory: false },
              requested_by: { hidden: false, readonly: false },
              approved_by: { hidden: true, readonly: true },
            });
          } else if (res.expense.expense_category_id == 10) {
            HandleMultiPropsChange({
              customer_id: { hidden: true, mandatory: false },
              delivery_address: { hidden: true, mandatory: false },
              proj_id: { hidden: true, mandatory: false },
              paid_through_acc_id: { hidden: false, mandatory: true },

              requested_by: { hidden: true, readonly: false },
              approved_by: { hidden: true, readonly: false },
            });
          }
          HandleStates(
            res.expense.expense_status,
            res.expense.expense_category_id
          );
        } else if (res.code === 401) {
          ErrorAlert(" Error Found Please contact the Admin");
        }
        store.stopLoading();
      });
    } else {
      store.setheader("CREATE NEW Company");

      GetRecords("/api/v2/expense/generate-expense-id/" + companyId).then(
        (res) => {
          if (res.success) {
            res = res.record;
            handleFieldChange("expense_no", res);
            console.log("expense_no", res);
          }
        }
      );

      HandleMultiPropsChange({
        customer_id: { mandatory: true },
        proj_id: { mandatory: true },
        expense_date: { mandatory: true },
      });
      HandleStates();
    }
  }, [option, expenseId]);

  function GetCustomerList() {
    getList({
      listUrl: URL_Account_Customer_List + "/" + companyId,
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res.data.list || [];
      }
      let customer_id_list = [...props.customer_id.customer_id_list];
      res?.map((item) => {
        customer_id_list.push({
          ...item,
          value: item.id,
          label: item.clientname,
        });
      });

      HandlePropsChange("customer_id", {
        ...props.customer_id,
        customer_id_list,
      });
    });
  }
  function GetPaidThoughtList() {
    getList({
      listUrl: "/api/v2/paymentreceipt/getbankcashgrouplists/" + companyId,
    }).then((res) => {
      console.log("paymentreceipt/getbankcashgrouplists", res);
      if (res.success) {
        res = res.data || [];
        res = res.map((item) => ({
          ...item,
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("paymentreceipt/getbankcashgrouplists", res);

        HandlePropsChange("paid_through_acc_id", {
          paid_through_acc_id_list: [
            ...initProps.paid_through_acc_id.paid_through_acc_id_list,
            ...res,
          ],
        });
      }
      console.log("URL_Account_Customer_Project_List", res);
    });
  }
  function GetExpenseList(Catagory = 9) {
    if (Catagory == 10) {
      getList({
        listUrl: "/api/v2/expense/getcoreexpenselist/" + companyId,
      }).then((res) => {
        if (res.success) {
          res = res;
          res = res.data || [];
          res = res.map((item) => ({
            ...item,
            value: item.id,
            label: item.ledger_name,
          }));

          HandlePropsChange("items", {
            particulars_list: [...initProps.items.particulars_list, ...res],
          });
        }
      });
    } else {
      getList({
        listUrl: URL_Account_Charts_List + companyId,
        field: "group_id",
        search: "28",
        count: 10000,
      }).then((res) => {
        console.log("URL_Account_Charts_List", res);

        if (res.success) {
          res = res?.data?.list || [];
          res = res.map((item) => ({
            ...item,
            value: item.id,
            label: item.ledger_name,
          }));

          HandlePropsChange("items", {
            particulars_list: [...initProps.items.particulars_list, ...res],
          });
        }
        console.log("URL_Account_Customer_Project_List", res);
      });
    }
  }
  function GetProjectList(customer_id) {
    getList({ listUrl: URL_Account_Customer_Project_List + customer_id }).then(
      (res) => {
        if (res.success) {
          res = res?.data?.list || [];
          res = res.map((item) => ({
            value: item.project_id,
            label: item.project_name,
          }));
          HandlePropsChange("proj_id", {
            proj_id_list: [...initProps.proj_id.proj_id_list, ...res],
          });
        }
      }
    );
  }
  function GetUserList() {
    getList({
      listUrl: "/users/getusers",
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.id,
          label: item.firstName + " " + item.lastName,
        }));
        console.log("users/getusers", res);

        HandlePropsChange("requested_by", {
          requested_by_list: [
            ...initProps.requested_by.requested_by_list,
            ...res,
          ],
        });
        HandlePropsChange("approved_by", {
          approved_by_list: [...initProps.approved_by.approved_by_list, ...res],
        });
      }
    });
  }
  function GetAddressList(customer_id) {
    let customer_id_list = GetRecordFromList(
      [...props.customer_id.customer_id_list],
      customer_id
    );
    console.log("GetAddressList", customer_id, customer_id_list);

    if (customer_id_list?.address != null) {
      let address_List = JSON.parse(customer_id_list.address);

      address_List = address_List
        .map((address) => {
          if (
            address?.line_1 != undefined ||
            address?.line_1 != "" ||
            address?.line_2 != undefined ||
            address?.line_2 != "" ||
            address?.state != undefined ||
            address?.state != "" ||
            address?.country != undefined ||
            address?.country != "" ||
            address?.pincode != undefined ||
            address?.pincode != ""
          ) {
            let addressLines = "new";
            if (address?.line_1 != undefined || address?.line_1 != "") {
              addressLines += address.line_1 + ", ";
            }
            if (address?.line_2 != undefined || address?.line_2 != "") {
              addressLines += address.line_2 + ", ";
            }
            if (address?.state != undefined || address?.state != "") {
              addressLines += address.state + ", ";
            }
            if (address?.country != undefined || address?.country != "") {
              addressLines += address.country + ", ";
            }
            if (address?.pincode != undefined || address?.pincode != "") {
              addressLines += address.pincode + ".";
            }

            return {
              label: address.name,
              value: addressLines,
            };
          }
        })
        .filter((item) => item != undefined);
      console.log("customer_id_list address_List", address_List);

      HandlePropsChange("delivery_address", {
        delivery_address_list: [
          ...initProps.delivery_address.delivery_address_list,
          ...address_List,
        ],
      });
    } else {
      HandlePropsChange("delivery_address", {
        delivery_address_list: [
          ...initProps.delivery_address.delivery_address_list,
        ],
      });
      handleFieldChange("delivery_address", "");
    }
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.company_id = companyId;
    delete savedata.expense_status;
    console.log("Submiting savedata", savedata);

    if (CheckMandatory() && CheckForExpensesRecords()) {
      UpdateRecord(URL_Account_Expense_Update + expenseId, savedata).then(
        (res) => {
          console.log("Resonce of saveRecord - ", res);
          if (res.success) {
            store.stopLoading();
            SuccessAlert("Expense Updated Successfully");

            store.navback();
          } else {
            store.stopLoading();
            ErrorAlert(
              res?.errormessage?.response?.data?.errorMessage ||
                " Error Found Please contact the Admin"
            );
          }
        }
      );
    }
  }
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.company_id = companyId;
    console.log("Submiting savedata", savedata);

    if (CheckMandatory() && CheckForExpensesRecords()) {
      saveRecord(URL_Account_Expense_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Expense Submitted Successfully");

          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  // *Check for the Expense records fields if empty or not
  function CheckForExpensesRecords() {
    let Expenses = [...Record.items];
    let MandatoryItems = { ...props.items.MandatoryItems };
    let out = undefined;

    Expenses.map((expense, index) => {
      console.log(
        "CheckForExpensesRecords",
        expense.charts_acc_id != 0 &&
          expense.amount != "" &&
          expense.amount != 0,
        expense.charts_acc_id != 0,
        expense.amount != "",
        expense.amount != 0,
        expense
      );
      if (
        expense.charts_acc_id != 0 &&
        expense.amount != "" &&
        expense.description != "" &&
        expense.amount != 0
      ) {
        out = out == false ? false : true;
        MandatoryItems = { ...MandatoryItems, [index]: false };
        console.log("CheckForExpensesRecords");
      } else {
        out = false;
        MandatoryItems = { ...MandatoryItems, [index]: true };
        console.log("CheckForExpensesRecords");
      }
      HandlePropsChange("items", {
        ...props.items,
        MandatoryItems: MandatoryItems,
      });
      console.log("CheckForExpensesRecords:MandatoryItems", {
        ...props.items.MandatoryItems,
      });
    });
    store.stopLoading();
    if (out == undefined || out == false)
      ErrorAlert("Please Fill the Expense Details");
    return out;
  }
  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleCustomer(value) {
    // Load Address List
    GetAddressList(value);
    // load Project List
    GetProjectList(value);
  }
  function HandleForInternal() {
    GetExpenseList(10);
    handleMultiFieldChange({
      expense_category_id: "10",
      paid_through_acc_id: 0,
      requested_by: 0,
    });
    HandleMultiPropsChange({
      customer_id: { hidden: true, mandatory: false },
      delivery_address: { hidden: true, mandatory: false },
      proj_id: { hidden: true, mandatory: false },
      paid_through_acc_id: { hidden: false, mandatory: true },

      requested_by: { hidden: true, readonly: false },
      approved_by: { hidden: true, readonly: false },
    });
  }
  function HandleByCompany() {
    GetExpenseList(8);
    handleMultiFieldChange({
      expense_category_id: "8",
      paid_through_acc_id: 0,
      requested_by: 0,
    });
    HandleMultiPropsChange({
      customer_id: { hidden: false, mandatory: true },
      delivery_address: { hidden: false, mandatory: false },
      proj_id: { hidden: false, mandatory: true },
      paid_through_acc_id: { hidden: false, mandatory: true },

      requested_by: { hidden: true, readonly: false },
      approved_by: { hidden: true, readonly: false },
    });
  }
  function HandleByEmployee() {
    GetExpenseList(9);
    handleMultiFieldChange({
      expense_category_id: "9",
      paid_through_acc_id: 15,
      requested_by: user.id,
    });
    HandleMultiPropsChange({
      customer_id: { hidden: false, mandatory: true },
      delivery_address: { hidden: false, mandatory: false },
      proj_id: { hidden: false, mandatory: true },
      paid_through_acc_id: { hidden: true, mandatory: false },
      requested_by: { hidden: false, readonly: false },
      approved_by: { hidden: true, readonly: true },
    });
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment_url = Record.attachment_url || [];
    UploadFile(attachment_url_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment_url", [...attachment_url, res.data]);
      }
    });
  }

  // *Handle Button
  function BtnSubmit() {
    let Stage = props.Stages.Stages_list;

    Stage[0].selected = false;
    Stage[0].completed = true;
    Stage[0].Name = "Submitted";
    Stage[1].selected = true;

    HandlePropsChange("Stages", { Stages_list: Stage });
    handleFieldChange("stage", "awaitingApproval");
  }
  function BtnApproval(stage) {
    let Stages = props.Stages.Stages_list;
    Stages[0].selected = false;
    Stages[0].completed = true;
    Stages[0].Name = "Submitted";
    if (stage === "Approve") {
      Stages[1].selected = false;
      Stages[1].completed = true;
      Stages[1].Name = "Approved";
      Stages[2].selected = true;
      Stages[2].Name = "Awaiting Payment";
      let savedata = {
        debitAccountId: 12,
        creditAccountId: 9,
        amount: Record.Total,
        company_id: 1,
        proj_id: 0,
        cust_id: 1,
        description: "Auto Created by Expense Creation",
        createdBy: store.getuser().id + "",
      };
      handleFieldChange("stage", "approved");
    } else {
      Stages[1].selected = false;
      Stages[1].completed = false;
      Stages[1].Name = "Rejected";
      Stages[1].icon = <MdCancel />;
      Stages = Stages.filter((item, index) => index != 2);
      handleFieldChange("stage", "rejected");
    }
    HandlePropsChange("Stages", { Stages_list: Stages });
  }
  function BtnPaid() {
    let Stages = props.Stages.Stages_list;
    Stages[0].selected = false;
    Stages[0].completed = true;
    Stages[0].Name = "Submitted";
    Stages[1].selected = false;
    Stages[1].completed = true;
    Stages[1].Name = "Approved";
    Stages[2].selected = false;
    Stages[2].completed = true;
    Stages[2].Name = "Paid";
    HandlePropsChange("Stages", { Stages_list: Stages });
    handleFieldChange("stage", "paid");
  }

  // calculate total value
  function TotalCalculation(
    items = Record.items,
    TDS = Number(Record.tds_payable_amount)
  ) {
    let subTotal = 0;

    let Total = 0;

    items.map((item) => {
      if (item.amount !== "" && item.amount !== undefined) {
        subTotal += Number(item.amount);
      }
    });

    Total = subTotal - TDS;
    handleFieldChange("expense_total", Total.toFixed(2));
  }

  // get specific record in list
  function GetRecordFromList(List = [], value) {
    List = List.filter((item) => item.value == value);
    if (List.length > 0) {
      return List[0];
    } else {
      return undefined;
    }
  }

  function HandleStates(
    state = "draft",
    expense_category_id = Record.expense_category_id
  ) {
    let Stages_list = [...props.Stages.Stages_list];
    switch (state) {
      case "draft":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = true;
        Stages_list[1].completed = false;
        if (expense_category_id != 9) {
          Stages_list[1].Name = "Completed";
          Stages_list = Stages_list.filter((item, index) => index <= 1);
        }
        break;
      case "approved":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        Stages_list[1].Name = "Approved";
        Stages_list[2].selected = true;
        Stages_list[2].completed = false;
        break;
      case "completed":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        Stages_list[1].Name = "Completed";
        Stages_list = Stages_list.filter((item, index) => index <= 1);
        break;

      case "awaiting approval":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[0].Name = "Submitted";
        Stages_list[1].selected = true;
        Stages_list[1].completed = false;
        break;
      case "rejected":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = false;
        Stages_list[1].Name = "Rejected";
        Stages_list[1].icon = <MdCancel />;
        Stages_list = Stages_list.filter((item, index) => index <= 1);
        break;
      case "paid":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[0].Name = "Draft";
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        Stages_list[1].Name = "Approved";
        Stages_list[2].selected = false;
        Stages_list[2].completed = true;
        Stages_list[2].Name = "Paid";
        break;
      default:
        break;
    }
    HandlePropsChange("Stages", { ...props.Stages, Stages_list });
  }

  return (
    <div className="flex flex-col h-full text-[12px] relative">
      <FormHeader
        State="Draft"
        FormName="Expense"
        FormNumber={Record.expense_no}
        Actions={[
          {
            ButtonName: "Submit",
            type: "primary",
            onClick: Submit,
            visible: Record.expense_status === true,
          },
          {
            ButtonName: "Sent For Approval",
            type: "primary",
            onClick: () => {
              UpdateRecord(URL_Account_Expense_AwaitingApproval + expenseId, {
                modifiedBy: store.getuser().id,
              }).then((res) => {
                HandleStates("awaiting approval");
                SuccessAlert("Expense sent for Approval");
                store.navback();
              });
            },
            visible:
              Record.expense_status === "draft" &&
              Record.expense_category_id == 9,
          },
          {
            ButtonName: "Completed",
            type: "primary",
            onClick: () => {
              UpdateRecord(
                URL_Account_Expense_Approval +
                  expenseId +
                  "/" +
                  store.getuser().id,
                {
                  modifiedBy: store.getuser().id,
                }
              ).then((res) => {
                HandleStates("completed");
                SuccessAlert("Expense Approved");
                store.navback();
              });
            },
            visible:
              Record.expense_status === "draft" &&
              user.role == 1 &&
              Record.expense_category_id != 9,
          },

          {
            ButtonName: "Update",
            type: "primary",
            onClick: update,
            visible: Record.expense_status === "draft" && option == "edit",
          },
          {
            ButtonName: "Approve",
            type: "primary",
            onClick: () => {
              UpdateRecord(
                URL_Account_Expense_Approval +
                  expenseId +
                  "/" +
                  store.getuser().id,
                {
                  modifiedBy: store.getuser().id,
                }
              ).then((res) => {
                HandleStates("approved");
                SuccessAlert("Expense Approved");
                store.navback();
              });
            },
            visible:
              Record.expense_status === "awaiting approval" && user.role == 1,
          },
          {
            ButtonName: "Reject",
            onClick: () => {
              UpdateRecord(URL_Account_Expense_Reject + expenseId, {
                modifiedBy: store.getuser().id,
              }).then((res) => {
                if (res.success) {
                  HandleStates("rejected");
                  SuccessAlert("Expense Rejected");
                  store.navback();
                }
              });
            },
            visible:
              Record.expense_status === "awaiting approval" && user.role == 1,
          },
          {
            ButtonName: "Pay",
            type: "primary",
            onClick: () => {
              // HandleStates("paid");
              HandlePropsChange("payment", {
                open: true,
                FormAcc: Record.paid_through_acc_id,
              });
            },
            visible:
              Record.expense_status === "approved" &&
              user.role == 1 &&
              Record.expense_category_id == 9,
          },
          {
            ButtonName: "Back",
            onClick: () => {
              navigate(-1);
            },
            visible: true,
          },
        ]}
      />
      <FormStage
        Stages={props.Stages.Stages_list}
        currentStage={Record.stage}
      />
      {props?.payment?.open && (
        <div className="absolute  flex h-full w-full z-50 rounded-lg flex-col items-center py-28 px-56 bg-[#000] bg-opacity-25">
          <h1 className="font-bold text-[18px] p-4 border-b w-full text-center bg-tertiary rounded-t-lg">
            {"Payment for the Requested Amount - " + Record.expense_total}
          </h1>
          <div className="bg-Alice_Blue flex h-full w-full items-center justify-center rounded-b-lg ">
            <div className="bg-Alice_Blue flex flex-col gap-5 items-center p-5 rounded-lg">
              <div // expense_date
                className={
                  "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                  (props.expense_date.hidden ? " hidden " : " flex ")
                }
              >
                <label className={"w-fit  min-w-[150px] max-w-full"}>
                  Payment Date
                  {props.expense_date.mandatory && (
                    <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                  )}
                </label>
                <input
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                    (props.expense_date.mandatory && props.expense_date.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"expense_date"}
                  type="date"
                  value={props?.expense_date?.date?.replace("T00:00:00Z", "")}
                  onChange={(event) => {
                    HandlePropsChange("expense_date", {
                      ...props.expense_date,
                      date: event.target.value + "T00:00:00Z",
                    });
                  }}
                />
              </div>
              <div // paid_through_acc_id
                className={
                  "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 "
                }
              >
                <label className={"w-fit  min-w-[150px] max-w-full"}>
                  Paid Through
                  {props.paid_through_acc_id.mandatory && (
                    <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                  )}
                </label>
                <select
                  id={"paid_through_acc_id"}
                  className={
                    " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                    (props.paid_through_acc_id.mandatory &&
                    props.paid_through_acc_id.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    HandlePropsChange("payment", {
                      ...props.payment,
                      toacc: event.target.value,
                    });
                  }}
                  value={props.payment.toacc}
                  aria-placeholder="please select sam"
                >
                  {props.paid_through_acc_id.paid_through_acc_id_list.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div // tds_payable_amount
                className={"flex items-center justify-between gap-3 "}
              >
                <div className="w-[50%] text-[14px] flex-grow ">
                  {"Amount Pay"}
                </div>
                <input // tds_payable_amount
                  className={
                    "w-[50%] border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none font-semibold  " +
                    (props.tds_payable_amount.mandatory &&
                    props.tds_payable_amount.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"tds_payable_amount"}
                  type="text"
                  value={props.payment.amount}
                  disabled={props.tds_payable_amount.readonly}
                  onChange={(event) => {
                    HandlePropsChange("payment", {
                      ...props.payment,
                      amount: event.target.value,
                    });
                  }}
                />
              </div>

              <div // attachment_urls
                className={"flex px-4 col-span-2 items-center"}
              >
                <h3 className="text-[14px]">Attachments</h3>
                <div // attachment_url
                  className={
                    "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                    (props.attachment_url.hidden ? " hidden " : " flex ")
                  }
                >
                  {option == "view" &&
                    (props?.attachment_url?.url == "" ||
                      props?.attachment_url?.url == undefined) && (
                      <button
                        className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                        onClick={() => {
                          document.getElementById("FileInput").click();
                        }}
                      >
                        <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                        Upload File
                      </button>
                    )}
                  {props?.attachment_url?.url != "" &&
                    props?.attachment_url?.url != undefined && (
                      <button
                        className="cursor-pointer text-[#3f4fff] bg-[#2a3cff] bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                        target="_blank"
                      >
                        <FaRegEye
                          className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                          onClick={() => {
                            store.navtotab(
                              baseurl +
                                "/files/preview?key=" +
                                props.attachment_url.url
                            );
                          }}
                        />
                        {props?.attachment_url?.url?.replaceAll(
                          "Journal_/",
                          ""
                        )}
                        <IoIosCloseCircleOutline
                          className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                          onClick={() => {
                            DeleteRecord(
                              "/files/deletefiles?key=" +
                                props.attachment_url.url
                            ).then((res) => {
                              if (res.success) {
                                HandlePropsChange("attachment_url", {
                                  ...props.attachment_url,
                                  url: "",
                                });
                                SuccessAlert("Attachment Removed");
                              } else {
                                ErrorAlert("Unable to delete the Attachment");
                              }
                            });
                          }}
                        />
                      </button>
                    )}
                  <input
                    type="file"
                    onChange={(event) => {
                      var bodyFormData = new FormData();
                      bodyFormData.append("file", event.target.files[0]);
                      UploadFile(attachment_url_Prefix, bodyFormData).then(
                        (res) => {
                          if (res.success) {
                            HandlePropsChange("attachment_url", {
                              ...props.attachment_url,
                              url: res.data,
                            });
                          }
                        }
                      );
                    }}
                    id="FileInput"
                    className="hidden"
                  />
                </div>
              </div>
              <span className=" flex gap-3 w-full justify-center ">
                <button
                  className="bg-Ocean_Blue text-secondary hover:bg-tertiary hover:text-primary p-3 rounded-lg"
                  onClick={() => {
                    if (
                      Record.expense_total == props.payment.amount &&
                      props.payment.toacc != ""
                    ) {
                      UpdateRecord(
                        `${URL_Account_Expense_paid}${expenseId}/${props.payment.FormAcc}/${props.payment.toacc}/${companyId}/${props.payment.amount}`,
                        {
                          modifiedBy: store.getuser().id,
                          paid_attachment_url: props?.attachment_url?.url,
                          paid_date: props?.expense_date?.date,
                        }
                      ).then((res) => {
                        HandleStates("paid");
                        SuccessAlert("Expense Paid");
                        HandlePropsChange("payment", { open: false });
                        store.navback();
                      });
                    } else {
                      if (props.payment.toacc == "") {
                        WarningAlert("Please Select Paid through Account.");
                      } else {
                        WarningAlert(
                          "Entered amount doesn't match the expense amount. Please check. "
                        );
                      }
                    }
                  }}
                >
                  Submit
                </button>
                <button
                  className="bg-Alice_Blue hover:bg-tertiary border hover:text-primary p-3 rounded-lg"
                  onClick={() => {
                    HandlePropsChange("payment", { open: false });
                  }}
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
        <div className="grid grid-cols-2 gap-2 w-[80%] mt-3">
          {user.role == 1 && (
            <div // expense_category_id for customer
              className={"flex gap-3 py-1 px-2 items-center "}
            >
              <div
                className="flex items-center gap-2 text-[14px] p-1 rounded-lg cursor-pointer border-[1px] border-secondary accent-Ocean_Blue hover:bg-Alice_Blue hover:border-Ocean_Blue "
                onClick={() => {
                  !props.expense_category_id.readonly && HandleByCompany();
                }}
              >
                <input
                  id={"date"}
                  type="radio"
                  disabled={props.expense_category_id.readonly}
                  checked={Record.expense_category_id == 8}
                />
                <h3>Paid by Company</h3>
              </div>
              <div
                className="flex items-center gap-2 text-[14px] p-1 rounded-lg cursor-pointer border-[1px] border-secondary accent-Ocean_Blue hover:bg-Alice_Blue hover:border-Ocean_Blue "
                onClick={() => {
                  !props.expense_category_id.readonly && HandleByEmployee();
                }}
              >
                <input
                  id={"date"}
                  type="radio"
                  disabled={props.expense_category_id.readonly}
                  checked={Record.expense_category_id == 9}
                />
                <h3>Paid by Employee</h3>
              </div>
            </div>
          )}
          {user.role == 1 && (
            <div
              div // expense_category_id For Internal
              className={"flex gap-3 py-1 px-2 items-center "}
            >
              <div
                className="flex items-center gap-2 text-[14px] p-1 rounded-lg cursor-pointer border-[1px] border-secondary accent-Ocean_Blue hover:bg-Alice_Blue hover:border-Ocean_Blue "
                onClick={() => {
                  !props.expense_category_id.readonly && HandleForInternal();
                }}
              >
                <input
                  id={"date"}
                  type="radio"
                  disabled={props.expense_category_id.readonly}
                  checked={Record.expense_category_id == 10}
                />
                <h3>For Internal</h3>
              </div>
            </div>
          )}

          <div // requested_by
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.requested_by.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Requested By
              {props.requested_by.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"requested_by"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.requested_by.mandatory && props.requested_by.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("requested_by", event.target.value);
                HandleCustomer(event.target.value);
                TotalCalculation();
              }}
              value={Record.requested_by}
              disabled={props.requested_by.readonly}
              aria-placeholder="please select sam"
            >
              {props.requested_by.requested_by_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div // customer_id
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.customer_id.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Customer
              {props.customer_id.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"customer_id"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.customer_id.mandatory && props.customer_id.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("customer_id", event.target.value);
                HandleCustomer(event.target.value);
                TotalCalculation();
              }}
              value={Record.customer_id}
              disabled={props.customer_id.readonly}
              aria-placeholder="please select sam"
            >
              {props.customer_id.customer_id_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div // paid_through_acc_id
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.paid_through_acc_id.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Paid Through
              {props.paid_through_acc_id.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"paid_through_acc_id"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.paid_through_acc_id.mandatory &&
                props.paid_through_acc_id.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("paid_through_acc_id", event.target.value);
                TotalCalculation();
              }}
              value={Record.paid_through_acc_id}
              disabled={props.paid_through_acc_id.readonly}
              aria-placeholder="please select sam"
            >
              {props.paid_through_acc_id.paid_through_acc_id_list.map(
                (item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                )
              )}
            </select>
          </div>

          <div // expense_date
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.expense_date.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Expense Date
              {props.expense_date.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                (props.expense_date.mandatory && props.expense_date.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"expense_date"}
              type="date"
              value={Record.expense_date.replace("T00:00:00Z", "")}
              disabled={props.expense_date.readonly}
              onChange={(event) => {
                handleFieldChange(
                  "expense_date",
                  event.target.value + "T00:00:00Z"
                );
              }}
            />
          </div>

          <div // delivery_address
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.delivery_address.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Delivery Address
              {props.delivery_address.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"delivery_address"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.delivery_address.mandatory && props.delivery_address.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("delivery_address", event.target.value);
              }}
              value={Record.delivery_address}
              disabled={props.delivery_address.readonly}
              aria-placeholder="please select sam"
            >
              {props.delivery_address.delivery_address_list.map(
                (item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                )
              )}
            </select>
          </div>

          <div // proj_id
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.proj_id.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Projects
              {props.proj_id.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"proj_id"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.proj_id.mandatory && props.proj_id.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("proj_id", event.target.value);
              }}
              value={Record.proj_id}
              disabled={props.proj_id.readonly}
              aria-placeholder="please select sam"
            >
              {props.proj_id.proj_id_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div // approved_by
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.approved_by.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Approved By
              {props.approved_by.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"approved_by"}
              className={
                " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                (props.approved_by.mandatory && props.approved_by.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("approved_by", event.target.value);
                HandleCustomer(event.target.value);
                TotalCalculation();
              }}
              value={Record.approved_by}
              disabled={props.approved_by.readonly}
              aria-placeholder="please select sam"
            >
              {props.approved_by.approved_by_list.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div // Transaction
          className={
            " text-[14px] w-[80%] px-1 mt-2 flex-col items-center gap-1 flex py-2 relative "
          }
        >
          <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
            <div className="text-[14px] w-full px-2 py-1">Expenses</div>
            <div className="text-[14px] w-full px-2 py-1">Note</div>
            <div className="text-[14px] w-full px-2 py-1">Amount</div>
            <span className=" mr-4 rounded-lg  cursor-pointer"></span>
          </div>
          {Record.items.map((item, index) => (
            <div
              className={
                " flex justify-around w-full gap-2 border-opacity-35 " +
                (props?.items?.MandatoryItems[index]
                  ? " border-2 border-[#ff2828] rounded-lg"
                  : " border-b border-Old_Silver ")
              }
              key={index}
            >
              <select // items.charts_acc_id
                id={"itemsexpenses"}
                className={
                  " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                  (props.items.mandatory && props.items.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  let items = Record.items;
                  items[index].charts_acc_id = event.target.value;
                  handleFieldChange("items", items);
                  // HandleParticulars(event.target.value, index);
                }}
                value={item.charts_acc_id}
                disabled={props.items.readonly}
              >
                {props.items.particulars_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <input // items.description
                className={
                  "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.items.mandatory && props.items.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"itemsdescription"}
                type="text"
                value={item.description}
                disabled={props.items.readonly}
                onChange={(event) => {
                  let items = Record.items;
                  items[index].description = event.target.value;
                  handleFieldChange("items", items);
                  TotalCalculation();
                }}
              />
              <input // items.Amount
                className={
                  "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.items.mandatory && props.items.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"itemsAmount"}
                type="number"
                value={item.amount}
                disabled={props.items.readonly}
                onChange={(event) => {
                  let items = Record.items;
                  items[index].amount = event.target.value;
                  handleFieldChange("items", items);
                  TotalCalculation();
                }}
              />
              {option != "view" && (
                <span
                  onClick={() => {
                    let items = Record.items;
                    items = items.filter(
                      (itemsitem, itemsindex) => itemsindex != index
                    );
                    handleFieldChange("items", items);
                    TotalCalculation(items);
                  }}
                  className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                >
                  X
                </span>
              )}
            </div>
          ))}
          {option != "view" && (
            <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
              <button
                className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                onClick={() => {
                  let items = Record.items;
                  items.push({
                    charts_acc_id: "",
                    description: "",
                    amount: "",
                  });
                  console.log("let items", items);

                  handleFieldChange("items", items);
                }}
              >
                Add New Line
              </button>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-2 w-[80%] mt-1 mb-5">
          <div // attachment_urls
            className={"flex flex-col px-4 col-span-2 "}
          >
            <h3 className="text-[14px]">Attachments</h3>
            <div // attachment_url
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.attachment_url.hidden ? " hidden " : " flex ")
              }
            >
              {option != "view" && (
                <button
                  className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                  onClick={() => {
                    document.getElementById("FileInput").click();
                  }}
                >
                  <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                  Upload File
                </button>
              )}
              {Record.attachment_url.map((attached, index) => (
                <button
                  key={index}
                  className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                  target="_blank"
                >
                  <FaRegEye
                    className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                    onClick={() => {
                      store.navtotab(
                        baseurl + "/files/preview?key=" + attached
                      );
                    }}
                  />
                  {attached.replaceAll("Journal_/", "")}
                  <IoIosCloseCircleOutline
                    className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                    onClick={() => {
                      let attachment_url = Record.attachment_url || [];
                      DeleteRecord("/files/deletefiles?key=" + attached).then(
                        (res) => {
                          if (res.success) {
                            attachment_url = attachment_url.filter(
                              (item, itemindex) => itemindex != index
                            );
                            handleFieldChange("attachment_url", [
                              ...attachment_url,
                            ]);
                            SuccessAlert("Attachment Removed");
                          } else {
                            ErrorAlert("Unable to delete the Attachment");
                          }
                        }
                      );
                    }}
                  />
                </button>
              ))}
              <input
                type="file"
                onChange={HandleAttachement}
                id="FileInput"
                className="hidden"
              />
            </div>
          </div>
          <div // expense_customer_notes
            className={
              "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
              (props.expense_customer_notes.hidden ? " hidden " : " flex ")
            }
          >
            <textarea
              className={
                "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                (props.expense_customer_notes.mandatory &&
                props.expense_customer_notes.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"expense_customer_notes"}
              value={Record.expense_customer_notes}
              disabled={props.expense_customer_notes.readonly}
              onChange={(event) => {
                if (event.target.value.length > 7) {
                  handleFieldChange(
                    "expense_customer_notes",
                    event.target.value
                  );
                } else {
                  handleFieldChange("expense_customer_notes", "Notes: ");
                }
              }}
            />
          </div>

          <div // tds_payable_amount
            className={"flex items-center justify-between px-4 "}
          >
            <div className="w-[30%] text-[14px]  text-Old_Silver flex-grow ">
              {"TDS Payable"}
            </div>
            <div className=" w-[70%] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
              <input // tds_payable_amount
                className={
                  "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none font-semibold  " +
                  (props.tds_payable_amount.mandatory &&
                  props.tds_payable_amount.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"tds_payable_amount"}
                type="text"
                value={Record.tds_payable_amount}
                disabled={props.tds_payable_amount.readonly}
                onChange={(event) => {
                  handleFieldChange("tds_payable_amount", event.target.value);
                  TotalCalculation(Record.items, event.target.value);
                }}
              />
            </div>
          </div>

          <div // expense_terms_and_conditions
            className={
              "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
              (props.expense_terms_and_conditions.hidden
                ? " hidden "
                : " flex ")
            }
          >
            <textarea
              className={
                "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                (props.expense_terms_and_conditions.mandatory &&
                props.expense_terms_and_conditions.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"expense_terms_and_conditions"}
              value={Record.expense_terms_and_conditions}
              disabled={props.expense_terms_and_conditions.readonly}
              onChange={(event) => {
                if (event.target.value.length > 20) {
                  handleFieldChange(
                    "expense_terms_and_conditions",
                    event.target.value
                  );
                } else {
                  handleFieldChange(
                    "expense_terms_and_conditions",
                    "Terms & Conditions: "
                  );
                }
              }}
            />
          </div>
          <div // expense_total
            className={"flex items-center justify-between px-4 "}
          >
            <div className="w-[30%] text-[14px] text-Old_Silver flex-grow ">
              {"Total (₹)"}
            </div>
            <div className=" w-[70%] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
              {Record.expense_total}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;
