import React, { useEffect } from "react";
import AccountsLayout from "../AccountsLayout";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import {
  DeleteRecord,
  GetListview,
  SuccessAlert,
  URLPRIFIX,
} from "../../../functions/api";
import Filters from "../../../Components/Fields/Filters";
import Pagination from "../../../Components/Fields/Pagination";
import {
  URL_Account_Expense_Delete,
  URL_Account_Expense_List,
  URL_Account_Receipt_List,
} from "../../../Constancs/apiconst";
import { getlocal } from "../../../functions/localstorage";

const ListView = ({
  width = "100%",
  height = "90%",
  header = [],
  records = [],
  onedit,
  onview,
  ondelete,
  onclick,
  Tablehead = " min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold ",
  Tablerecord = "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] overflow-hidden",
}) => {
  return (
    <div
      className={`flex  overflow-auto `}
      style={{ height: height, width: width }}
    >
      <table className="table-auto w-full h-fit">
        <thead className="sticky top-0 bg-secondary text-left mt-10 border-b">
          <tr className="px-1">
            {header.map(([name, label], index) => {
              if (label !== "id") {
                return (
                  <th key={index} className={Tablehead}>
                    {name}
                  </th>
                );
              }
            })}
          </tr>
        </thead>

        <tbody>
          {records.map((item, index) => (
            <tr
              key={index}
              className={
                "border-b h-8 hover:bg-[#b3b3f5] rounded-xl " +
                (item.error && ` bg-red bg-opacity-35 `)
              }
            >
              {header.map(([name, label], fieldindex) => {
                // console.log("Listview actioon",field == "ACTION" && (ondelete!=undefined||onview!=undefined||onedit!=undefined),(ondelete!=undefined||onview!=undefined||onedit!=undefined));

                if (label == "ACTION") {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      <div className="flex gap-7">
                        {onedit && item?.expense_status === "draft" && (
                          <img
                            src="/Assets/images/pencil.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onedit(item["id"]);
                            }}
                          />
                        )}
                        {onview && (
                          <img
                            src="/Assets/images/eye.png "
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              onview(item["id"]);
                            }}
                          />
                        )}
                        {ondelete && (
                          <img
                            src="/Assets/images/trash.png"
                            className=" cursor-pointer w-5 h-5 hover:scale-125"
                            onClick={() => {
                              const confirmDelete = window.confirm(
                                "Are you sure you want to delete?"
                              );
                              if (confirmDelete) {
                                ondelete(item["id"]);
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  );
                } else if (label === "activeStatus") {
                  return (
                    <td key={fieldindex} className={Tablerecord}>
                      {item[label] ? "Active" : "Inactive"}
                    </td>
                  );
                } else if (
                  label.toLowerCase().includes("date") &&
                  item[label] != undefined
                ) {
                  const [date, time] = item[label].split("T");
                  if (time != null && time != undefined) {
                    // console.log("Time ", date, time);

                    return (
                      <td
                        key={fieldindex}
                        className={Tablerecord}
                        onClick={() => {
                          onclick && onclick(item["id"]);
                        }}
                      >
                        {date}
                      </td>
                    );
                  } else {
                    return (
                      <td key={fieldindex} className={Tablerecord}>
                        ---
                      </td>
                    );
                  }
                } else if (label != "id") {
                  return (
                    <td
                      key={fieldindex}
                      className={Tablerecord}
                      onClick={() => {
                        onclick && onclick(item["id"]);
                      }}
                    >
                      {item[label]}
                    </td>
                  );
                } else {
                  <td key={fieldindex} className={Tablerecord}>
                    ---
                  </td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ExpenseList = () => {
  const location = useLocation();
  const { companyId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const user = getlocal("system")?.user || {};
  const tablename = "companies";
  const tableURL = "/companies/getusers";
  const DeleteURL = "/companies/deleteusers/";
  const Header = [
    ["Expense Number", "expense_no"],
    ["Delivery Address", "delivery_address"],
    ["Expense Date", "expense_date"],
    ["Notes", "expense_customer_notes"],
    ["Amount", "expense_total"],
    ["Status", "expense_status"],
    ["Action", "ACTION"],
  ];
  const store = useStore();
  const init = {
    filterfield: "all",
    search: "",
    sort: "desc",
    currentpage: 1,
    totalpage: 1,
  };

  function getListOfRecords(
    field = init.filterfield,
    search = init.search,
    sort = init.sort,
    props = 1
  ) {
    if (user.role == 1) {
      GetListview({
        listUrl: URL_Account_Expense_List + companyId,
        store: store,
        field: field,
        search: search,
        sort: sort,
        page: props,
      });
    } else {
      GetListview({
        listUrl: "/api/v2/expense/getallexpense",
        store: store,
        field: field,
        search: search,
        sort: sort,
        page: props,
      });
    }
  }

  useEffect(() => {
    store.setmodule("Expenses", init).then((res) => {
      store.stopLoading();
      if (user.role == 1) {
        getListOfRecords();
      } else {
        getListOfRecords("created_by", user.id, init.sort);
      }
    });
  }, []);

  function change(props) {
    console.log("search data - ", props);
    getListOfRecords(props.field, props.search, props.sort);
  }
  function onpage(props) {
    console.log("Pagination number - ", props);
    getListOfRecords(
      store.getvalue("filterfield"),
      store.getvalue("search"),
      store.getvalue("sort"),
      props
    );
  }

  return (
    <div className="grid grid-rows-10 h-full ">
      <div className="row-span-1 relative">
        <Filters fields={Header} onchange={change} />
        {store.getprops("add") && (
          <div className="absolute right-10 top-0">
            <button
              className="bg-primary text-secondary py-2 text-[12px] px-6 rounded-3xl"
              onClick={() => {
                store.removemodule(
                  tablename.substring(0, tablename.length - 1)
                );
                store.navTo(
                  "/" + URLPRIFIX + "/" + companyId + "/accounts/expense/create"
                );
              }}
            >
              Add Expense
            </button>
          </div>
        )}
      </div>
      <div className="row-span-9 h-full w-full ">
        <div
          className="py-5 px-[30px] h-full max-2xl:w-[1000px] w-full"
          contentEditable={false}
        >
          <ListView
            width={user.role === 1 ? "1080px" : ""}
            header={Header}
            records={store.getvalue("list")}
            onedit={
              store.getprops("update")
                ? (index) => {
                    store.navTo(
                      "/" +
                        URLPRIFIX +
                        "/" +
                        companyId +
                        "/accounts/expense/edit/" +
                        index
                    );
                  }
                : undefined
            }
            onview={
              store.getprops("view")
                ? (index) => {
                    store.navTo(
                      "/" +
                        URLPRIFIX +
                        "/" +
                        companyId +
                        "/accounts/expense/view/" +
                        index
                    );
                  }
                : undefined
            }
            // ondelete={
            //   store.getprops("delete")
            //     ? (index) => {
            //         DeleteRecord(URL_Account_Expense_Delete + index, {
            //           modifiedBy: store.getuser().id,
            //         }).then((res) => {
            //           if (res.success) {
            //             SuccessAlert("Deleted Successfully");
            //             GetListview({
            //               listUrl: URL_Account_Expense_List + companyId,
            //               store: store,
            //               field: store.getvalue("filterfield"),
            //               search: store.getvalue("search"),
            //               sort: store.getvalue("sort"),
            //               page: store.getvalue("currentpage"),
            //             });
            //           }
            //         });
            //       }
            //     : undefined
            // }
          />
          <div className="float-right mr-8 mt-6">
            <Pagination onchange={onpage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseList;
