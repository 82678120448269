import React from "react";

const CardWith3Section = ({ Icon, Content, Action }) => {
  return (
    <div className="flex justify-stretch bg-Azureish_White h-[130px] w-full rounded-lg overflow-hidden">
      <div className="bg-tertiary basis-[120px] grow-0 flex justify-center items-center m-[10px] rounded-lg">
        {Icon}
      </div>
      <div className=" basis-[210px] grow p-2">{Content}</div>
      <div className=" basis-[210px] grow-0 py-3 px-2 flex justify-end">{Action}</div>
    </div>
  );
};

export default CardWith3Section;
