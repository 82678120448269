import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  DeleteRecord,
  ErrorAlert,
  GetDateTimeFormat,
  getList,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineCancel, MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { IoIosCloseCircleOutline, IoMdAttach } from "react-icons/io";

import { PrimaryBTN } from "../../../Constancs/cssconst";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import {
  URL_Account_Charts_List,
  URL_Account_Client_List,
  URL_Account_Customer_List,
  URL_Account_Customer_Project_List,
  URL_Account_Expense_ListBy_ProjectId,
  URL_Account_Invoice_By_ID,
  URL_Account_Invoice_Create,
  URL_Account_Invoice_PDF_By_ID,
  URL_Account_Invoice_Update,
  URL_Account_Invoice_UpdateStatus,
} from "../../../Constancs/apiconst";
import { GiFinishLine } from "react-icons/gi";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const InvoiceForm = () => {
  const { companyId, option, invoiceId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [pdfurl, setpdfurl] = useState("");

  const navigate = useNavigate();
  const getrecord = `/users/users/${invoiceId}`;
  const store = useStore();
  let storage = getStorage();

  // *initial values
  const init = {
    invoice_no: "",
    customer_id: 0,
    invoice_date: "",
    delivery_address: "",
    invoice_sub_total: 0,
    discount_type: "6",
    discount_value: 0,
    invoice_discount: 0,
    invoice_total: 0,
    invoice_due_date: "",
    invoice_customer_notes: "Notes: ",
    invoice_terms_and_conditions: "Terms & Conditions: ",
    attachment_url: [],
    items: [
      {
        project_id: 0,
        doc_id: 0,
        charts_acc_id: 0,
        trademark_url: "",
        description: "",
        amount: "",
      },
    ],
    expense_id: [],
  };

  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    attachment_url_Prefix: { number: "invoice" },

    invoice_no: {},
    customer_id: { customer_id_list: [{ value: "", label: "Please Choose" }] },
    invoice_date: {},
    delivery_address: {
      delivery_address_list: [{ value: "", label: "Please Choose" }],
    },
    invoice_sub_total: {},

    discount_value: {},
    discount_type: {
      discount_type_list: [
        { value: "6", label: "%" },
        { value: "5", label: "₹" },
      ],
    },
    invoice_discount: {},
    invoice_total: {},
    invoice_due_date: {},
    invoice_customer_notes: {},
    invoice_terms_and_conditions: {},
    attachment_url: {},
    items: {
      MandatoryItems: {},
      project_list: [{ value: 0, label: "Please Choose" }],
      salesOrder_list: [{ value: 0, label: "Please Choose" }],
      document_list: [[{ value: 0, label: "Please Choose" }]],
      url: "",
      trademark_url: "",
      IndexForAttach: 0,
    },
    expense_id: {
      expense_id_list: [],
    },
    Stages: {
      Stages_list: [
        {
          Name: "Draft",
          icon: <FaPenNib />,
          selected: true,
          completed: false,
        },
        {
          Name: "Awaiting Invoice",
          icon: <GiFinishLine />,
          selected: false,
          completed: false,
        },
        {
          Name: "Awaiting Payment",
          icon: <GiFinishLine />,
          selected: false,
          completed: false,
        },
      ],
    },
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  // *and change on dependencies of option and id changes
  useEffect(() => {
    store.setmodule("invoices", init);
    store.stopLoading();
    LoadInitialData();
  }, [option, invoiceId, companyId]);

  async function LoadInitialData() {
    const localprops = getlocal("properties")?.invoices || {};
    HandleMultiPropsChange(localprops);
    await GetRecords(URL_Account_Customer_List + "/" + companyId).then(
      (res) => {
        if (res.success) {
          res = res.record.list;
          console.log("URL_Account_Customer_List", res);
          let customer_id_list = initProps.customer_id.customer_id_list;
          res.map((customer) =>
            customer_id_list.push({
              ...customer,
              label: customer.clientname,
              value: customer.id,
            })
          );
          HandlePropsChange("customer_id", {
            ...props.customer_id,
            customer_id_list,
          });
        }
      }
    );
    await getList({
      listUrl: URL_Account_Charts_List + companyId,
      count: 1000,
    }).then((res) => {
      if (res.success) {
        console.log("URL_Account_Charts_List", res);
        res = res?.data?.list || [];
        res = res.filter((item) => item.parent_id === 18);
        let salesOrder_list = initProps.items.salesOrder_list;

        res.map((sales) =>
          salesOrder_list.push({
            ...sales,
            label: sales.ledger_name,
            value: sales.id,
          })
        );
        HandlePropsChange("items", {
          ...props.items,
          salesOrder_list,
        });
      }
    });

    if (invoiceId !== undefined) {
      GetRecords(URL_Account_Invoice_By_ID + invoiceId).then((res) => {
        if (res.success) {
          res = res.record;
          console.log(
            "URL_Account_Invoice_By_ID",
            res,
            res.invoice.delivery_address
          );
          let invoice = res.invoice;
          HandleStates(invoice.invoice_status);
          let doclist = [];
          res.items = res.items.map((item, index) => {
            doclist.push([{ label: item.doc_name, value: item.doc_id }]);
            return {
              project_id: item.project_id,
              doc_id: item.doc_id,
              charts_acc_id: item.charts_acc_id,
              trademark_url: item.trademark_url,
              description: item.description,
              amount: item.amount,
            };
          });
          HandlePropsChange("items", {
            ...props.items,
            document_list: [...doclist],
          });

          handleMultiFieldChange({
            invoice_no: invoice.invoice_no,
            customer_id: invoice.customer_ledger_id,
            invoice_date: invoice.invoice_date,
            delivery_address: invoice.delivery_address,
            invoice_sub_total: invoice.invoice_sub_total,
            discount_value: invoice.discount_value,
            discount_type: invoice.discount_type,
            invoice_discount: invoice.invoice_discount,
            invoice_total: invoice.invoice_total,
            invoice_status: invoice.invoice_status,
            invoice_due_date: invoice.invoice_due_date,
            invoice_customer_notes: "Notes: " + invoice.invoice_customer_notes,
            invoice_terms_and_conditions:
              "Terms & Conditions: " + invoice.invoice_terms_and_conditions,
            items: res.items || [],
            expense_id: JSON.parse(invoice.expense_id) || [],
            attachment_url: invoice.attachment_url || [],
            invoice_pdf_url: invoice.invoice_pdf_url,
          });
          setpdfurl(invoice.invoice_pdf_url);
          HandlePropsChange("attachment_url_Prefix", {
            number: invoice.invoice_no,
          });
          GetAddressList(invoice.customer_ledger_id);
          GetProjectList(invoice.customer_ledger_id);
        }
      });
      if (option === "view") {
        HandleMultiPropsChange({
          invoice_date: { readonly: true },
          invoice_due_date: { readonly: true },
          customer_id: { readonly: true },
          delivery_address: { readonly: true },
          items: { readonly: true },
          discount_value: { readonly: true },
          invoice_customer_notes: { readonly: true },
          invoice_terms_and_conditions: { readonly: true },
        });
      }
      // else {
      // invoice_date: { readonly: true  },
      // customer_id: { readonly: true},
      // delivery_address: {readonly: true },
      // items: {readonly: true },
      // discount_value: {readonly: true },
      // invoice_customer_notes: {readonly: true },
      // invoice_terms_and_conditions: {readonly: true }
      //   });
      // }
    } else {
      store.setheader("CREATE NEW Company");

      await GetRecords("/api/v2/invoice/generate-invoice-id/" + companyId).then(
        (res) => {
          if (res.success) {
            res = res?.record || "invoice";
            // console.log("GetDateTimeFormat", GetDateTimeFormat());

            handleFieldChange("invoice_no", res);
            HandlePropsChange("attachment_url_Prefix", { number: res });
            console.log("invoicenumber", res);
          }
        }
      );

      HandleMultiPropsChange({
        customer_id: { mandatory: true },
        invoice_date: { mandatory: true },
        invoice_due_date: { mandatory: true },
        delivery_address: { mandatory: true },
      });

      // if query auto choose the customer and the porject
      let cust_id = queryParams.get("cust_id");
      let proj_id = queryParams.get("proj_id");
      let doc_id = queryParams.get("doc_id");
      if (doc_id) {
        let items = Record.items;
        items[0].doc_id = doc_id;
        handleFieldChange("items", items);
      }
      cust_id && HandleCustomer(cust_id);
      proj_id && Handleproject_id(proj_id);
    }
  }
  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.expense_id = JSON.stringify(savedata.expense_id);
    savedata.company_id = companyId;
    savedata.invoice_terms_and_conditions =
      savedata.invoice_terms_and_conditions.replace("Terms & Conditions:", "");
    savedata.invoice_customer_notes = savedata.invoice_customer_notes.replace(
      "Notes:",
      ""
    );
    console.log("Submiting savedata", savedata);
    if (CheckMandatory() && CheckForProjectRecords()) {
      UpdateRecord(URL_Account_Invoice_Update + invoiceId, savedata).then(
        (res) => {
          console.log("Resonce of saveRecord - ", res);
          if (res.success) {
            store.stopLoading();
            SuccessAlert("Invoice Updated Successfully");
            store.navback();
          } else {
            store.stopLoading();
            ErrorAlert(
              res?.errormessage?.response?.data?.errorMessage ||
                " Error Found Please contact the Admin"
            );
          }
        }
      );
    }
  }
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.expense_id = JSON.stringify(savedata.expense_id);
    savedata.company_id = companyId;
    savedata.invoice_terms_and_conditions =
      savedata.invoice_terms_and_conditions.replace("Terms & Conditions:", "");
    savedata.invoice_customer_notes = savedata.invoice_customer_notes.replace(
      "Notes:",
      ""
    );
    console.log("Submiting savedata", savedata);

    if (CheckMandatory() && CheckForProjectRecords()) {
      if (Record.invoice_sub_total != 0 && Record.invoice_total != 0) {
        saveRecord(URL_Account_Invoice_Create, savedata).then((res) => {
          console.log("Resonce of saveRecord - ", res);
          if (res.success) {
            handleFieldChange("invoice_status", "draft");
            store.stopLoading();
            SuccessAlert("Invoice Submitted Successfully");
            store.navback();
          } else {
            store.stopLoading();
            ErrorAlert(
              res.errormessage.response.data
              // res?.errormessage?.response?.data?.errorMessage ||
              //   " Error Found Please contact the Admin"
            );
          }
        });
      } else {
        WarningAlert("Please Submit the Amount for the Project and Expense");
        store.stopLoading();
      }
    }
  }
  function GenerateInvoice() {
    store.startLoading();
    UpdateRecord(URL_Account_Invoice_UpdateStatus + invoiceId, {
      modifiedBy: store.getuser().id,
    }).then((res) => {
      console.log("Resonce of saveRecord - ", res);
      if (res.success) {
        SuccessAlert("Invoice Generated Successfully");
        store.stopLoading();
        handleFieldChange("invoice_status", "completed");
        // store.navback();
        HandleStates("completed");
        printpdf();
      } else {
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.response?.data?.errorMessage ||
            " Error Found Please contact the Admin"
        );
      }
      store.stopLoading();
    });
  }
  function printpdf() {
    // store.startLoading();

    GetRecords("/api/v2/invoice/invoices/pdf/" + invoiceId).then((res) => {
      console.log("pdfurldemo", res);
      if (res.success) {
        res = res?.record?.invoice_pdf_url || "";
        //handleFieldChange("invoice_no",res)
        setpdfurl(res);
        if (res != "") {
          window.open(res, "_blank");
        } else {
          console.error("PDF URL is not defined");
        }
      } else {
        console.error("PDF URL is Network Error");
      }
    });
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Check for the Project records fields if empty or not
  function CheckForProjectRecords() {
    let projects = [...Record.items];
    let MandatoryItems = { ...props.items.MandatoryItems };
    let out = undefined;
    projects.map((project, index) => {
      console.log(
        "CheckForProjectRecords",
        project.project_id != 0 &&
          project.charts_acc_id != 0 &&
          project.amount != "" &&
          project.amount != 0,
        project.project_id != 0,
        project.charts_acc_id != 0,
        project.description,
        "description",
        project.description != "",
        project.amount != "",
        project.amount != 0,
        project
      );
      if (
        project.project_id != 0 &&
        project.charts_acc_id != 0 &&
        project.description != "" &&
        project.amount != "" &&
        project.amount != 0
      ) {
        out = out == false ? false : true;
        MandatoryItems = { ...MandatoryItems, [index]: false };
        console.log("CheckForProjectRecords");
      } else {
        out = false;
        MandatoryItems = { ...MandatoryItems, [index]: true };
        console.log("CheckForProjectRecords");
      }
      HandlePropsChange("items", {
        ...props.items,
        MandatoryItems: MandatoryItems,
      });
      console.log("CheckForProjectRecords:MandatoryItems", {
        ...props.items.MandatoryItems,
      });
    });
    store.stopLoading();
    if (out == undefined || out == false)
      ErrorAlert("Please Fill the Project Details");
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  //* Loading Dropdowns
  function GetProjectList(customer_id) {
    getList({
      listUrl: "/api/v2/Customer/getclientprojects/" + customer_id,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          value: item.project_id,
          label: item.project_name,
        }));
        HandlePropsChange("items", {
          project_list: [...initProps.items.project_list, ...res],
        });
      }
    });
  }
  function GetAddressList(customer_id) {
    let customer_id_list = GetRecordFromList(
      [...props.customer_id.customer_id_list],
      customer_id
    );
    console.log("GetAddressList", customer_id, customer_id_list);

    if (customer_id_list?.address != null) {
      let address_List = JSON.parse(customer_id_list.address);

      address_List = address_List
        .map((address) => {
          if (
            address?.line_1 != undefined ||
            address?.line_1 != "" ||
            address?.line_2 != undefined ||
            address?.line_2 != "" ||
            address?.state != undefined ||
            address?.state != "" ||
            address?.country != undefined ||
            address?.country != "" ||
            address?.pincode != undefined ||
            address?.pincode != ""
          ) {
            let addressLines = "";
            if (address?.line_1 != undefined || address?.line_1 != "") {
              addressLines += address.line_1 + ", ";
            }
            if (address?.line_2 != undefined || address?.line_2 != "") {
              addressLines += address.line_2 + ", ";
            }
            if (address?.state != undefined || address?.state != "") {
              addressLines += address.state + ", ";
            }
            if (address?.country != undefined || address?.country != "") {
              addressLines += address.country + ", ";
            }
            if (address?.pincode != undefined || address?.pincode != "") {
              addressLines += address.pincode + ".";
            }

            return {
              label: address.name,
              value: addressLines,
            };
          }
        })
        .filter((item) => item != undefined);
      console.log("customer_id_list address_List", address_List);

      HandlePropsChange("delivery_address", {
        delivery_address_list: [
          ...initProps.delivery_address.delivery_address_list,
          ...address_List,
        ],
      });
    } else {
      HandlePropsChange("delivery_address", {
        delivery_address_list: [
          ...initProps.delivery_address.delivery_address_list,
        ],
      });
      handleFieldChange("delivery_address", "");
    }
  }
  async function GetDocumentList(project_id, index = 0) {
    await getList({
      listUrl: "/api/v2/Customer/getprojectsdocs/" + project_id,
    }).then((res) => {
      if (res.success) {
        res = res?.data?.list || [];
        res = res.map((item) => ({
          ...item,
          value: item.id,
          label: item.task_title,
        }));
        console.log("Customer/getprojectsdocs", res, index);
        let document_list = [...props.items.document_list];

        document_list[index] = [{ value: 0, label: "Please Choose" }, ...res];
        HandlePropsChange("items", {
          ...props.items,
          document_list: document_list,
        });
      }
    });
  }
  // *Handle Change in the fields
  function HandleCustomer(value) {
    // load Address list
    GetAddressList(value);
    // load Project list
    GetProjectList(value);

    // choose the sale account if the customer has sale account
    // const customer = GetRecordFromList(
    //   props.customer_id.customer_id_list,
    //   value
    // );

    const customer = GetRecords(
      `/api/v2/Customer/getclient/` + value + "/" + companyId
    ).then((res) => {
      console.log("HandleCustomer customer", res.record.receivable_ledger_id);

      if (value == "") {
        handleFieldChange("customer_id", value);
      } else if (
        res.record.receivable_ledger_id != undefined &&
        res.record.receivable_ledger_id != 0 &&
        res.record.receivable_ledger_id != null
      ) {
        let items = Record.items;
        items[0].charts_acc_id = res.record.receivable_ledger_id || 0;
        handleFieldChange("items", items);
        handleFieldChange("customer_id", value);
      } else {
        if (
          res.record.receivable_ledger_id === 0 ||
          res.record.receivable_ledger_id === undefined ||
          res.record.receivable_ledger_id === null
        ) {
          ErrorAlert(
            "The customer is not linked with the Chart of Account. Please add the Receivable Account to the Customer"
          );
        }
      }
    });
    // console.log("customer ledger response",customer);

    // Calculation trigger for the Auto calculation
    TotalCalculation();
  }
  function Handlecharts_acc_id(value, index) {
    let items = Record.items;

    items[index].charts_acc_id = value;

    handleFieldChange("items", items);
    TotalCalculation();
  }
  async function Handleproject_id(value, index = 0, items = Record.items) {
    if (index.toString() != "") {
      items[index].project_id = value;
      handleFieldChange("items", items);
    }
    let projectDetails = {};
    let listofExpense = [];
    let expense_id = [...Record.expense_id];
    let project_ids = [...items];
    project_ids = project_ids.map((item) => Number(item.project_id));
    console.log("CheckForProjectRecords:project_ids", project_ids);
    expense_id = expense_id.filter((item) =>
      project_ids.includes(item.project_id)
    );
    console.log("CheckForProjectRecords:expense_id", expense_id);

    if (value != 0 && value != "") {
      GetDocumentList(value, index);
      if (
        !Record.expense_id.filter((item) => item.project_id == value).length > 0
      ) {
        projectDetails = GetRecordFromList(
          [...props.items.project_list],
          value
        );

        await getList({
          listUrl:
            URL_Account_Expense_ListBy_ProjectId + value + "/" + companyId,
        }).then((res) => {
          if (res.success && res.data != undefined) {
            res = res?.data || [];
            if (res.length > 0) {
              listofExpense = res.map((item) => ({
                item_id:item?.child?.item_id,
                name: item?.child?.ledger_name,
                description: item?.child?.description,
                amount: item.child.amount,
                project_name: projectDetails?.label,
                project_id: projectDetails?.value,
              }));
              console.log("CheckForProjectRecords:res", listofExpense);

              expense_id = [...expense_id, ...listofExpense];
            }
          }
        });
      }
    }
    TotalCalculation(items, [...expense_id]);
    handleFieldChange("expense_id", [...expense_id]);
  }
  function HandleDiscountValue(discount_value = Record.discount_value) {
    let invoice_discount = 0;
    let subTotal = Number(Record.invoice_sub_total);
    discount_value = Number(discount_value);
    if (Record.discount_type === "6") {
      invoice_discount = (discount_value / 100) * subTotal;
    } else if (Record.discount_type === "5") {
      invoice_discount = discount_value;
    }
    console.log("invoice_discount", invoice_discount);

    handleFieldChange("invoice_discount", invoice_discount.toFixed(2));
    handleFieldChange(
      "invoice_total",
      (subTotal - invoice_discount).toFixed(2)
    );
  }
  function HandleDiscountType(discountType) {
    let discount_value = 0;
    let subTotal = Number(Record.invoice_sub_total);

    handleFieldChange("invoice_discount", discount_value?.toFixed(2));
    handleFieldChange("invoice_total", subTotal?.toFixed(2));
    handleFieldChange("discount_value", 0);
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment_url = Record.attachment_url || [];
    UploadFile(props.attachment_url_Prefix.number, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment_url", [...attachment_url, res.data]);
      }
    });
  }
  function HandleTradeMarkAttachment(event, index) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(props.attachment_url_Prefix.number, bodyFormData).then((res) => {
      console.log(
        "props.attachment_url_Prefix",
        event,
        props?.items?.IndexForAttach || 0,
        res
      );

      if (res.success) {
        let items = Record?.items || [];
        items[props?.items?.IndexForAttach || 0].trademark_url =
          res?.data || "";
        handleFieldChange("items", items);
        if (props?.items?.url != "") {
          DeleteAttachement(props?.items?.url);
        }
        SuccessAlert("Attachment Added Successfully");
        HandlePropsChange("items", {
          ...props.items,
          url: res?.data || "",
          IndexForAttach: index,
        });
      }
    });
  }

  function DeleteAttachement(URL) {
    return DeleteRecord("/files/deletefiles?key=" + URL);
  }

  // calculate total value
  function TotalCalculation(
    items = Record.items,
    expense_id = Record.expense_id,
    discount_value = Record.discount_value
  ) {
    let subTotal = 0;
    let discountValue = 0;
    let Total = 0;
    let discountType = Record.discount_type;
    discount_value = Number(discount_value);
    let expense_Amount = 0;
    expense_id.map((item) => {
      expense_Amount += item.amount;
    });
    subTotal += expense_Amount;
    console.log("URL_Account_Expense_ListBy_ProjectId Expense", expense_Amount);

    items.map((item) => {
      if (item.amount !== "" && item.amount !== undefined) {
        subTotal += Number(item.amount);
      }
    });

    if (discountType === "6") {
      discountValue = (discount_value / 100) * subTotal;
    } else if (discountType === "5") {
      discountValue = discount_value;
    } else {
      discountValue = 0;
    }
    Total = subTotal - discountValue;
    handleFieldChange("invoice_sub_total", subTotal.toFixed(2));
    handleFieldChange("invoice_discount", discountValue.toFixed(2));
    handleFieldChange("invoice_total", Total.toFixed(2));
  }

  // get specific record in list
  function GetRecordFromList(List = [], value) {
    List = List.filter((item) => item.value == value);
    if (List.length > 0) {
      return List[0];
    } else {
      return undefined;
    }
  }

  function HandleStates(state = "Draft") {
    let Stages_list = [...props.Stages.Stages_list];
    switch (state) {
      case "draft":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = true;
        Stages_list[1].completed = false;
        Stages_list[1].Name = "Generate Invoice";
        break;
      case "completed":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        Stages_list[1].Name = "Completed";
        Stages_list[2].selected = true;
        Stages_list[2].completed = false;
        break;
      case "paid":
        Stages_list[0].selected = false;
        Stages_list[0].completed = true;
        Stages_list[1].selected = false;
        Stages_list[1].completed = true;
        Stages_list[2].selected = false;
        Stages_list[2].completed = true;
        Stages_list[2].Name = "Paid";
        break;
      default:
        break;
    }
    HandlePropsChange("Stages", { ...props.Stages, Stages_list });
  }

  return (
    <AccountsLayout HeaderTitle={"Invoice Form"} key={companyId}>
      <div className="flex flex-col h-full text-[12px]">
        <FormHeader
          State="Draft"
          FormName="Invoice"
          FormNumber={Record.invoice_no}
          Actions={[
            {
              ButtonName: "Submit",
              type: "primary",
              onClick: Submit,
              visible: invoiceId === undefined,
            },
            {
              ButtonName: "Update",
              type: "primary",
              onClick: update,
              visible: Record.invoice_status === "draft" && option == "edit",
            },
            {
              ButtonName: "Generate Invoice",
              type: "primary",
              onClick: GenerateInvoice,
              visible: Record.invoice_status === "draft",
            },
            {
              ButtonName: "Print as PDF",
              type: "primary",
              onClick: printpdf,
              visible:
                Record.invoice_status === "completed" ||
                Record.invoice_status === "paid",
            },
            {
              ButtonName: "Cancel Invoice",
              type: "primary",
              onClick: () => {
                const confirmDelete = window.confirm(
                  "Are you sure you want to cancel this invoice No - " +
                    Record.invoice_no +
                    " ? "
                );
                if (confirmDelete) {
                  DeleteRecord("/api/v2/invoice/deleteinvoice/" + invoiceId, {
                    modifiedBy: store.getuser().id,
                  }).then((res) => {
                    if (res.success) {
                      SuccessAlert("Cancelled Successfully");
                      navigate(-1);
                    }
                  });
                }
              },
              visible:
                invoiceId !== undefined && Record.invoice_status !== "draft",
            },

            {
              ButtonName: "Back",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        <FormStage Stages={props.Stages.Stages_list} />
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-5">
            <div // customer_id
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.customer_id.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Customer
                {props.customer_id.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"customer_id"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.customer_id.mandatory && props.customer_id.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  HandleCustomer(event.target.value);
                }}
                value={Record.customer_id}
                disabled={props.customer_id.readonly}
                aria-placeholder="please select sam"
              >
                {props.customer_id.customer_id_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div // invoice_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.invoice_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Invoice Date
                {props.invoice_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.invoice_date.mandatory && props.invoice_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"invoice_date"}
                type="date"
                value={Record.invoice_date.split("T")[0]}
                disabled={props.invoice_date.readonly}
                onChange={(event) => {
                  const invoiceDate = event.target.value + "T00:00:00Z";
                  const dueDate =
                    Record.invoice_due_date.split("T")[0] + "T00:00:00Z";
                  if (new Date(invoiceDate) > new Date(dueDate)) {
                    WarningAlert(
                      "Invoice date cannot be greater than the due date."
                    );
                  } else {
                    handleFieldChange("invoice_date", invoiceDate);
                  }
                }}
                // onChange={(event) => {
                //   handleFieldChange(
                //     "invoice_date",
                //     event.target.value + "T00:00:00Z"
                //   );
                // }}
              />
            </div>

            <div // delivery_address
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.delivery_address.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Delivery Address
                {props.delivery_address.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"delivery_address"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.delivery_address.mandatory &&
                  props.delivery_address.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("delivery_address", event.target.value);
                }}
                value={Record.delivery_address}
                disabled={props.delivery_address.readonly}
                aria-placeholder="please select sam"
              >
                {props.delivery_address.delivery_address_list.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div // invoice_due_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.invoice_due_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Due Date
                {props.invoice_due_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.invoice_due_date.mandatory &&
                  props.invoice_due_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"invoice_due_date"}
                type="date"
                value={Record.invoice_due_date.split("T")[0]}
                disabled={props.invoice_due_date.readonly}
                onChange={(event) => {
                  const dueDate = event.target.value + "T00:00:00Z";
                  const invoiceDate =
                    Record.invoice_date.split("T")[0] + "T00:00:00Z";
                  if (new Date(dueDate) < new Date(invoiceDate)) {
                    WarningAlert(
                      "Due date cannot be earlier than the invoice date."
                    );
                  } else {
                    handleFieldChange("invoice_due_date", dueDate);
                  }
                }}
                // onChange={(event) => {
                //   handleFieldChange(
                //     "invoice_due_date",
                //     event.target.value + "T00:00:00Z"
                //   );
                // }}
              />
            </div>
          </div>
          <h1
            className={
              " text-[16px] font-semibold w-[95%] px-5 mt-2 gap-1 flex  relative "
            }
          >
            Projects
          </h1>
          <div // items
            className={
              " text-[14px] w-[95%] px-3 flex-col items-center gap-1 flex py-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Projects</div>
              <div className="text-[14px] w-full px-2 py-1">Document</div>
              <div className="text-[14px] w-full px-2 py-1">Account</div>
              <div className="text-[14px] w-full px-2 py-1">
                Remarks/TradeMark
              </div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {Record.items.map((item, index) => (
              <div
                className={
                  " flex justify-around w-full gap-2 border-opacity-35 " +
                  (props?.items?.MandatoryItems[index]
                    ? " border-2 border-[#ff2828] rounded-lg"
                    : " border-b border-Old_Silver ")
                }
                key={index}
              >
                <select // item.project_id
                  id={"items"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                    (props?.items?.mandatory && props?.items?.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    Handleproject_id(event?.target?.value, index);
                  }}
                  value={item?.project_id}
                  disabled={props?.items?.readonly}
                >
                  {props?.items?.project_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <select // item.doc_id
                  id={"items"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                    (props?.items?.mandatory && props?.items?.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    let items = Record.items;
                    items[index].doc_id = event.target.value;
                    handleFieldChange("items", items);
                  }}
                  value={item?.doc_id}
                  disabled={props?.items?.readonly}
                >
                  {props?.items?.document_list &&
                    props?.items?.document_list[index]?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </select>
                <select // item?.charts_acc_id
                  id={"items"}
                  className={
                    " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props?.items?.mandatory && props?.items?.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  onChange={(event) => {
                    Handlecharts_acc_id(event.target.value, index);
                  }}
                  value={item?.charts_acc_id}
                  disabled={props?.items?.readonly}
                >
                  {props.items.salesOrder_list.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <span // item.description
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none flex  " +
                    (props?.items?.mandatory && props?.items?.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                >
                  <input // item.description
                    className=" border-Old_Silver border-b pl-2 "
                    id={"items"}
                    type="text"
                    value={item?.description}
                    disabled={props?.items?.readonly}
                    onChange={(event) => {
                      let items = Record.items;
                      items[index].description = event.target.value;
                      handleFieldChange("items", items);
                    }}
                  />
                  <button //item.trademark_url
                    className={
                      " w-6 h-6 flex justify-center items-center border rounded-lg " +
                      (item.trademark_url != "" && item.trademark_url != null
                        ? " bg-[#7df2aa] "
                        : " bg-secondary ")
                    }
                    onClick={() => {
                      if (
                        item.trademark_url != "" &&
                        item.trademark_url != null
                      ) {
                        HandlePropsChange("items", {
                          ...props.items,
                          trademark_url: true,
                          url: item.trademark_url,
                          IndexForAttach: index,
                        });
                      } else {
                        document.getElementById("doc_id_input").click();
                        HandlePropsChange("items", {
                          ...props.items,
                          IndexForAttach: index,
                        });
                      }
                    }}
                  >
                    {item.trademark_url != "" && item.trademark_url != null ? (
                      <TiTick />
                    ) : (
                      <IoMdAttach />
                    )}
                  </button>
                  <input
                    type="file"
                    onChange={(event) => {
                      HandleTradeMarkAttachment(event, index);
                    }}
                    id="doc_id_input"
                    className="hidden"
                  />
                </span>
                <input // item.amount
                  className={
                    "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props?.items?.mandatory && props?.items?.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"items"}
                  type="text" // Change type to 'text' for full control over input
                  value={item?.amount}
                  disabled={props?.items?.readonly}
                  onChange={(event) => {
                    let newAmount = event.target.value;
                    const regex = /^[0-9]*\.?[0-9]*$/;
                    if (regex.test(newAmount) && newAmount >= 0) {
                      let items = Record.items;
                      items[index].amount = newAmount;
                      handleFieldChange("items", items);
                      TotalCalculation();
                    } else {
                      newAmount = item.amount;
                    }
                  }}
                />

                {option !== "view" && (
                  <span
                    onClick={() => {
                      let items = Record.items;
                      items = items.filter(
                        (itemsitem, itemsindex) => itemsindex != index
                      );
                      handleFieldChange("items", items);
                      TotalCalculation(items);
                      Handleproject_id("", "", items);
                    }}
                    className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                  >
                    X
                  </span>
                )}
              </div>
            ))}

            {option !== "view" && (
              <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
                <button
                  className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                  onClick={() => {
                    let items = [...Record.items];
                    let charts_acc_id = items[0]?.charts_acc_id || 0;
                    let doc_id = [...props.items.document_list];
                    items.push({
                      project_id: 0,
                      doc_id: 0,
                      charts_acc_id: charts_acc_id,
                      trademark_url: "",
                      description: "",
                      amount: "",
                    });
                    doc_id.push([{ value: 0, label: "Please Choose" }]);

                    handleFieldChange("items", items);
                    HandlePropsChange("items", {
                      ...props.items,
                      document_list: doc_id,
                    });
                  }}
                >
                  Add New Line
                </button>
              </div>
            )}
          </div>
          {Record.expense_id.length != 0 && (
            <h1
              className={
                " text-[16px] font-semibold w-[95%] px-5 mt-2 gap-1 flex  relative "
              }
            >
              Expense
            </h1>
          )}
          {Record.expense_id.length != 0 && (
            <div // expense_id
              className={
                " text-[14px] w-[95%] px-3 flex-col items-center gap-1 flex py-2 relative "
              }
            >
              <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
                <div className="text-[14px] w-full px-2 py-1">Project Name</div>
                <div className="text-[14px] w-full px-2 py-1">Expense</div>
                <div className="text-[14px] w-full px-2 py-1">Description</div>
                <div className="text-[14px] w-full px-2 py-1">Amount</div>
                <span className=" mr-4 rounded-lg  cursor-pointer"></span>
              </div>
              {Record.expense_id.map((item, index) => (
                <div
                  className="flex justify-around w-full gap-2 border-b border-Old_Silver border-opacity-35"
                  key={index}
                >
                  <h1 className="w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none ">
                    {item.project_name}
                  </h1>
                  <h1 className="w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none ">
                    {item.name}
                  </h1>
                  <h1 className="w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none ">
                    {item.description}
                  </h1>
                  <h1 className="w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none ">
                    {item.amount}
                  </h1>
                  {option !== "view" && (
                    <span
                      onClick={() => {
                        let expense_id = Record.expense_id;
                        expense_id = expense_id.filter(
                          (itemsitem, itemsindex) => itemsindex != index
                        );
                        handleFieldChange("expense_id", expense_id);
                        TotalCalculation(Record.items, expense_id);
                      }}
                      className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                    >
                      X
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}

          <div // attachment_urls
            className={
              " text-[14px] w-[95%] px-5 flex-col gap-1 flex py-2 relative "
            }
          >
            <h3 className="text-[14px] ">Attachments</h3>
            <div // attachment_url
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.attachment_url.hidden ? " hidden " : " flex ")
              }
            >
              {option != "view" && (
                <button
                  className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                  onClick={() => {
                    document.getElementById("FileInput").click();
                  }}
                >
                  <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                  Upload File
                </button>
              )}
              {Record.attachment_url.map((attached, index) => (
                <button
                  key={index}
                  className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                  target="_blank"
                >
                  <FaRegEye
                    className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                    onClick={() => {
                      store.navtotab(
                        baseurl + "/files/preview?key=" + attached
                      );
                    }}
                  />
                  {attached.replaceAll(
                    props.attachment_url_Prefix.number + "/",
                    ""
                  )}
                  {invoiceId === undefined && (
                    <IoIosCloseCircleOutline
                      className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                      onClick={() => {
                        let attachment_url = Record.attachment_url || [];

                        DeleteAttachement(attached).then((res) => {
                          if (res.success) {
                            attachment_url = attachment_url.filter(
                              (item, itemindex) => itemindex != index
                            );
                            handleFieldChange("attachment_url", [
                              ...attachment_url,
                            ]);
                            SuccessAlert("Attachment Removed Successfully");
                          } else {
                            WarningAlert("Unable to remove Attachment");
                          }
                        });
                      }}
                    />
                  )}
                </button>
              ))}
              <input
                type="file"
                onChange={HandleAttachement}
                id="FileInput"
                className="hidden"
              />
            </div>
          </div>
          <div className="flex flex-grow w-[95%] pb-2">
            <div className="w-[45%]">
              <div // invoice_customer_notes
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.invoice_customer_notes.hidden ? " hidden " : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.invoice_customer_notes.mandatory &&
                    props.invoice_customer_notes.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"invoice_customer_notes"}
                  value={Record.invoice_customer_notes}
                  disabled={props.invoice_customer_notes.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 7) {
                      handleFieldChange(
                        "invoice_customer_notes",
                        event.target.value
                      );
                    } else {
                      handleFieldChange("invoice_customer_notes", "Notes: ");
                    }
                  }}
                />
              </div>
              <div // invoice_terms_and_conditions
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.invoice_terms_and_conditions.hidden
                    ? " hidden "
                    : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.invoice_terms_and_conditions.mandatory &&
                    props.invoice_terms_and_conditions.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"invoice_terms_and_conditions"}
                  value={Record.invoice_terms_and_conditions}
                  disabled={props.invoice_terms_and_conditions.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 20) {
                      handleFieldChange(
                        "invoice_terms_and_conditions",
                        event.target.value
                      );
                    } else {
                      handleFieldChange(
                        "invoice_terms_and_conditions",
                        "Terms & Conditions: "
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-[55%] flex flex-col gap-3">
              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Sub Total"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.invoice_sub_total}
                </div>
              </div>

              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Discounts"}
                </div>
                <div // invoice_discount
                  className={
                    "flex text-[14px] w-[210px] h-full px-3 flex-row items-center gap-3 col-span-1 " +
                    (props.invoice_discount.hidden ? " hidden " : " flex ")
                  }
                >
                  <input
                    className={
                      " w-full px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none" +
                      (props.discount_value.mandatory &&
                      props.discount_value.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"discount_value"}
                    type="number"
                    value={Record.discount_value}
                    disabled={props.discount_value.readonly}
                    onChange={(event) => {
                      if (
                        Record.discount_type == "5" &&
                        event.target.value >= Number(Record.invoice_sub_total)
                      ) {
                        WarningAlert(
                          "Discount amount not greater then the Sub-Total"
                        );
                      } else if (
                        Record.discount_type == "6" &&
                        event.target.value >= 100
                      ) {
                        WarningAlert(
                          "Discount amount not greater then the 100%"
                        );
                      } else {
                        handleFieldChange("discount_value", event.target.value);
                        TotalCalculation(
                          Record.items,
                          Record.expense_id,
                          event.target.value
                        );
                      }
                    }}
                  />
                  <select
                    id={"discount"}
                    className={
                      " w-full px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none" +
                      (props.discount_type.mandatory && props.discount_type.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    onChange={(event) => {
                      handleFieldChange("discount_type", event.target.value);
                      HandleDiscountType(event.target.value);
                    }}
                    value={Record.discount_type}
                    disabled={props.discount_type.readonly}
                  >
                    {props.discount_type.discount_type_list.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {(Record.invoice_discount == "0" ||
                  Record.invoice_discount == "0.00"
                    ? ""
                    : "-") + Record.invoice_discount}
                </div>
              </div>

              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Total (₹)"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.invoice_total}
                </div>
              </div>
            </div>
          </div>
        </div>
        {props?.items?.trademark_url && (
          <span className="bg-black bg-opacity-15 absolute flex w-[99%] h-[98%] justify-center items-center transition-all">
            <span className="flex bg-secondary w-[85%] h-[90%] p-3 rounded-lg relative flex-col gap-1 justify-between">
              <MdOutlineCancel
                className="w-7 h-7 absolute bg-secondary rounded-full p-1 -right-2.5 -top-2.5 cursor-pointer"
                onClick={() => {
                  HandlePropsChange("items", {
                    ...props.items,
                    trademark_url: false,
                    url: "",
                  });
                }}
              />
              {props?.items?.url?.includes("pdf") ? (
                <iframe
                  className="h-[90%] w-fit "
                  src={baseurl + "/files/preview?key=" + props?.items?.url}
                />
              ) : (
                <img
                  className="max-h-[94%] w-fit max-w-[98%]"
                  s
                  src={baseurl + "/files/preview?key=" + props?.items?.url}
                />
              )}
              <span className="flex justify-center gap-5">
                <button
                  className="border p-1 rounded-lg hover:bg-tertiary"
                  onClick={() => {
                    document.getElementById("doc_id_input").click();
                  }}
                >
                  Change
                </button>
                <button
                  className="border p-1 rounded-lg hover:bg-tertiary"
                  onClick={() => {
                    DeleteAttachement(props?.items?.url).then((res) => {
                      if (res.success) {
                        let items = Record?.items || [];
                        items[props?.items?.IndexForAttach || 0].trademark_url =
                          "";
                        handleFieldChange("items", items);
                        HandlePropsChange("items", {
                          ...props.items,
                          trademark_url: false,
                          url: "",
                        });
                        SuccessAlert("Attachment Removed Successfully");
                      }
                    });
                  }}
                >
                  Delete
                </button>
              </span>
            </span>
          </span>
        )}
      </div>
    </AccountsLayout>
  );
};

export default InvoiceForm;
