import React, { useEffect } from "react";
import { contentHeader, TableHeader } from "../../Constancs/cssconst";
import { getList, GetRecords, URLPRIFIX } from "../../functions/api";
import ListView from "./ListView";
import { getlocal } from "../../functions/localstorage";

const DocumentUpdate = ({ store, props }) => {
  const user = getlocal("system")?.user || {};
  const Header = [
    ["Client Name", "clientName"],
    ["Project Name", "projectName"],
    ["Document Name", "task_title"],
    ["Task Name", "subtask_title"],
    ["Priority", "priority"],
    ["Status", "status"],
    ["Assigned To", "assigned_to_username"],
    ["Due Date", "dueDate"],
    ["Delay days", "delay_days"],
  ];
  useEffect(() => {
    console.log("System Data -", user);
    GetDocList({
      listUrl: "/subtask/getsubtaskstatuslist",
      field: user?.role == "1" ? "all" : "assigned_to",
      search: user?.role == "1" ? "" : user?.id,
    });
  }, []);

  function GetDocList({
    listUrl = "/subtask/getsubtasklist",
    field = "all",
    search = "",
    sort = "asc",
    page = 1,
    count = 100,
  }) {
    getList({
      listUrl: listUrl,
      field: field,
      search: search,
      sort: sort,
      page: page,
      count: count,
    }).then((res) => {
      let list = res?.data?.list;
      list = list.map((item) => {
        let duedate = new Date(item?.dueDate || "");
        let currentdate = new Date();
        let diffirence = duedate - currentdate;
        let diffDays = Math.floor(diffirence / (1000 * 60 * 60 * 24)) + 1;
        if (Math.sign(diffDays) === -1) {
          item.delay_days = diffDays;
          item.error = true;
        } else {
          item.delay_days = "0";
        }
      });

      store.setvalue("DocumentList", res?.data?.list);
    });
  }

  return (
    <>
      <h1 className={contentHeader}>Document Updates</h1>
      {user?.role == "1" && (
        <span className="absolute top-3 right-5 flex gap-3 max-sm:hidden">
          <h3>{"Sort : "}</h3>
          <select
            className="bg-Alice_Blue border rounded-lg"
            onChange={(event) => {
              const fieldName = event.target.value;
              if (fieldName === "delay_days") {
                GetDocList({ field: "dueDate"});
              } else if (fieldName != "") {
                GetDocList({ field: fieldName });
              }
            }}
          >
            <option value="">Please select</option>
            {Header.map(([label, name], index) => (
              <option value={name}>By {label}</option>
            ))}
          </select>
        </span>
      )}
      <ListView
        Tablehead={"bg-Alice_Blue min-w-[150px] pr-[10px]  " + TableHeader}
        Tablerecord=" max-sm:text-[26px] md:text-[20px] lg:text-[14px] xl:text-[14px] cursor-pointer "
        width={"100%"}
        height={"100%"}
        onclick={(index) => {
          store.navTo("/" + URLPRIFIX + "/tasks/view/" + index);
        }}
        header={Header}
        records={store.getvalue("DocumentList")}
      />
    </>
  );
};

export default DocumentUpdate;
