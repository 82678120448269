import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import "react-toastify/dist/ReactToastify.css";

const CategoryForm = () => {
  const { option, id } = useParams();
  const tablename = "category";
  const setrecord = "/clientcategory/addCategory";
  const getrecord = `/clientcategory/getcategory/${id}`;
  const updaterecord = "/clientcategory/updatecategory/" + id;

  const store = useStore();
  const init = {
    categoryName: "",
    activeStatus: true,
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      // store.setheader("Edit " + tablename);

      store.setprops("activeStatus", { hidden: false });
      if (option === "view") {
        store.setprops("activeStatus", { hidden: false, readonly: true });
        store.setprops("categoryName", {
          hidden: false,
          readonly: true,
          mandatory: false,
        });
      } else {
        store.setprops("activeStatus", { hidden: false, readonly: false });
        store.setprops("categoryName", {
          hidden: false,
          readonly: false,
          mandatory: false,
        });
      }

      GetRecords(getrecord).then((res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.categoryName = res.record.response[0].categoryName;
          init.activeStatus = res.record.response[0].activeStatus;
          store.reset(init);
        } else if (res.code === 401) {
          ErrorAlert(" Error Found Please contact the Admin");
        }
        // else if (res.code === 401) {
        //   store.logout();
        //   store.navTo("/login");
        // }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: false, readonly: false });
      store.setprops("categoryName", {
        hidden: false,
        readonly: false,
        mandatory: true,
      });
    }
  }, []);
  useEffect(() => {
    console.log(store.getrecord());
  });
  function update() {
    store.startLoading();
    console.log(store.getrecord());
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        SuccessAlert("Category Updated Successfully");
        store.stopLoading();
        store.reset(init);
        store.navback();
      } else {
        console.log("errormessage", res);
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.data ||
            "Unknown Error Found. Please contact the Admin"
        );
      }
    });
  }

  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    const { Notification, ...dataWithoutNotification } = data;
    dataWithoutNotification.modifiedBy = store.getuser().id;
    dataWithoutNotification.createdBy = store.getuser().id;
    if (typeof dataWithoutNotification.activeStatus === "string") {
      dataWithoutNotification.activeStatus = dataWithoutNotification.activeStatus == "true" ? true : false;
    }
    // let data = store.getrecord();
    // data.modifiedBy = store.getuser().id;
    // data.createdBy = store.getuser().id;
    if (CheckMandatory(tablename, store)) {
      saveRecord(setrecord, dataWithoutNotification).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        store.stopLoading();
        if (res.success) {
          SuccessAlert("Category Submitted Successfully");
          store.reset(init);
          store.navback();
        } else {
          console.log("errormessage", res);
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: false,
          // visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: false,
          // visible: option === "edit" && store.getprops("update"),
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-6">
        <String name="categoryName" label="Category Name :" />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
      </div>
    </FormView>
  );
};

export default CategoryForm;
