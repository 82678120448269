import {
  ErrorAlert,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  UploadFile,
} from "../../functions/api";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import { getlocal, getStorage, setStorage } from "../../functions/localstorage";
import {
  URL_Account_Company_By_ID,
  URL_Account_Company_Create,
  URL_Account_Company_Update,
} from "../../Constancs/apiconst";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";

const CompanyForm = () => {
  const { option, id } = useParams();
  const tablename = "companies";
  const navigate = useNavigate();
  const getrecord = `/users/users/${id}`;
  const setrecord = "/accounting/addnewcompany";
  let storage = getStorage();

  const store = useStore();

  const init = {
    company_logo: "",
    company_name: "",
    company_address_line1: "",
    company_address_line2: "",
    company_address_city: "",
    company_address_pinode: "",
    company_address_state: "",
    company_phone_alternative: "",
    company_website_url: "",
    company_phone: "",
    company_email: "",
    company_status: "true",
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    company_logo: { hidden: false, mandatory: false, readonly: false },
    company_name: { hidden: false, mandatory: false, readonly: false },
    company_address_line1: { hidden: false, mandatory: false, readonly: false },
    company_address_line2: { hidden: false, mandatory: false, readonly: false },
    company_address_city: { hidden: false, mandatory: false, readonly: false },
    company_address_pinode: {
      hidden: false,
      mandatory: false,
      readonly: false,
    },
    company_address_state: { hidden: false, mandatory: false, readonly: false },
    company_phone_alternative: {
      hidden: false,
      mandatory: false,
      readonly: false,
    },
    company_website_url: { hidden: false, mandatory: false, readonly: false },
    company_phone: { hidden: false, mandatory: false, readonly: false },
    company_email: { hidden: false, mandatory: false, readonly: false },
    company_status: { hidden: false, mandatory: false, readonly: false },
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  useEffect(() => {
    store.setmodule(tablename, init).then((res) => {
      store.stopLoading();
      const localprops = getlocal("properties")?.companies || {};

      HandleMultiPropsChange(localprops);

      // console.log("Params",param);
      if (id !== undefined) {
        // store.setheader(option.toUpperCase() + " Company");
        HandlePropsChange("company_status", { hidden: false });
        if (option === "view") {
          HandleMultiPropsChange({
            company_logo: { readonly: true },
            company_name: { readonly: true },
            company_address_line1: { readonly: true },
            company_address_line2: { readonly: true },
            company_address_city: { readonly: true },
            company_address_pinode: { readonly: true },
            company_address_state: { readonly: true },
            company_phone_alternative: { readonly: true },
            company_website_url: { readonly: true },
            company_phone: { readonly: true },
            company_email: { readonly: true },
            company_status: { readonly: true },
          });
        } else {
          HandleMultiPropsChange({
            company_logo: { readonly: false },
            company_name: { readonly: false },
            company_address_line1: { readonly: false },
            company_address_line2: { readonly: false },
            company_address_city: { readonly: false },
            company_address_pinode: { readonly: false },
            company_address_state: { readonly: false },
            company_phone_alternative: { readonly: false },
            company_website_url: { readonly: false },
            company_phone: { readonly: false },
            company_email: { readonly: false },
            company_status: { readonly: false },
          });
        }
        GetRecords(URL_Account_Company_By_ID + id).then((res) => {
          console.log("Companies Data - ", res, res.record);
          if (res.success) {
            res = res.record;
            handleMultiFieldChange({
              company_logo: res.company_logo,
              company_name: res.company_name,
              company_address_line1: res.company_address_line1,
              company_address_line2: res.company_address_line2,
              company_address_city: res.company_address_city,
              company_address_pinode: res.company_address_pinode,
              company_address_state: res.company_address_state,
              company_phone_alternative: res.company_phone_alternative,
              company_website_url: res.company_website_url,
              company_phone: res.company_phone,
              company_email: res.company_email,
              company_status: res.company_status,
            });
          } else if (res.code === 401) {
                    ErrorAlert(" Error Found Please contact the Admin");
                  }
          // else if (res.code === 401) {
          //   store.logout();
          //   store.navTo("/login");
          // }
        });
      } else {
        // store.setheader("CREATE NEW Company");
        // props for the Mandatory field
        HandleMultiPropsChange({
          company_name: { mandatory: true },
          company_email: { mandatory: true },
          company_status: { hidden: true },
        });
      }
    });
  }, [option, id]);

  useEffect(() => {
    console.log("Record State change -> ", Record);
  }, [Record]);

  useEffect(() => {
    console.log("Props State change -> ", props);
  }, [props]);
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  function update() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    const email = savedata.company_email;  
    // if(!email){
    //   store.stopLoading();
    //   ErrorAlert("Enter Email Address")
    //   return;
    // }
    // if (savedata.company_email && !validateEmail(email)) {
    //   store.stopLoading();
    //   ErrorAlert("Invalid email address. Please enter a valid email.");
    //   return;  
    // }
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      UpdateRecord(URL_Account_Company_Update + id, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Company Updated Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
      const email = savedata.company_email;  
        if (savedata.company_email  && !validateEmail(email)) {
          store.stopLoading();
          ErrorAlert("Invalid email address. Please enter a valid email.");
          return;  
        }
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      saveRecord(URL_Account_Company_Create, savedata).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Company Submitted Successfully");
          store.navback();
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data?.errorMessage ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }

  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function handleFieldChange(fieldName, value) {
    console.log("Record :",fieldName, value);
    
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  function Delete({ child }) {
    return <div>{child}</div>;
  }

  function TestCompo() {
    return (
      <div>
        <h1>New Test piece</h1>
      </div>
    );
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            navigate(-1);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined && props.add,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && id !== undefined && props.update,
        },
      ]}
    >
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3 relative">
        <div // company_name
          className={
            "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_name.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Company Name
            {props.company_name.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
              (props.company_name.mandatory && props.company_name.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_name"}
            type="text"
            value={Record.company_name}
            disabled={props.company_name.readonly}
            onChange={(event) => {
              handleFieldChange("company_name", event.target.value);
            }}
          />
        </div>
        <div // company_email
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_email.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Company E-Mail
            {props.company_email.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_email.mandatory && props.company_email.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_email"}
            type="text"
            value={Record.company_email}
            disabled={props.company_email.readonly}
            onChange={(event) => {
              handleFieldChange("company_email", event.target.value);
            }}
          />
        </div>
        <div // company_phone
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_phone.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Company Phone
            {props.company_phone.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_phone.mandatory && props.company_phone.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_phone"}
            type="text"
            value={Record.company_phone}
            disabled={props.company_phone.readonly}
            onChange={(event) => {
              handleFieldChange("company_phone", event.target.value);
            }}
          />
        </div>
        <div // company_phone_alternative
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_phone_alternative.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Alternative Phone
            {props.company_phone_alternative.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_phone_alternative.mandatory &&
              props.company_phone_alternative.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_phone_alternative"}
            type="text"
            value={Record.company_phone_alternative}
            disabled={props.company_phone_alternative.readonly}
            onChange={(event) => {
              handleFieldChange(
                "company_phone_alternative",
                event.target.value
              );
            }}
          />
        </div>
        <div // company_website_url
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_website_url.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Website URL
            {props.company_website_url.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_website_url.mandatory &&
              props.company_website_url.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_website_url"}
            type="text"
            value={Record.company_website_url}
            disabled={props.company_website_url.readonly}
            onChange={(event) => {
              handleFieldChange("company_website_url", event.target.value);
            }}
          />
        </div>
        <div // company_status
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_status.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Active Status
            {props.company_status.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <select
            id={"company_status"}
            className={
              " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
              (props.company_status.mandatory && props.company_status.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            onChange={(event) => {
              handleFieldChange("company_status", event.target.value);
            }}
            value={Record.company_status}
            disabled={props.company_status.readonly}
          >
            <option value={""}>Please Choose</option>
            <option value={"true"}>Active</option>
            <option value={"false"}>InActive</option>
          </select>
        </div>
        <div // company_logo
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_logo.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Company Logo
            {props.company_logo.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
         
          {Record.company_logo  ? (
             <div className="col-span-1 flex items-center border rounded-md p-2">
             <Attachement  name="company_logo" fileurl={Record.company_logo} className="w-30" />
             <button
               className="hover:bg-tertiary p-2 h-fit w-fit rounded-[10px]"
               onClick={() => setRecord({ ...Record, company_logo: "" })}
             >
               X
             </button>
           </div>
  ) : (
    <>No Data</>
  )}

          {option !== "view" && (
          <FileInput
                prefix={"COMPANYLOGO"}
                onchange={(file) => {       
                    handleFieldChange("company_logo", file);
                    store.setvalue("company_logo",file)
                }}
              />)}

          
        </div>
        <div className="col-span-2 max-sm:col-span-1 font-semibold mt-1 px-3 hover:">
          Company Address Info
        </div>
        <div // company_address_line1
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_address_line1.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Address line1
            {props.company_address_line1.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_address_line1.mandatory &&
              props.company_address_line1.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_address_line1"}
            type="text"
            value={Record.company_address_line1}
            disabled={props.company_address_line1.readonly}
            onChange={(event) => {
              handleFieldChange("company_address_line1", event.target.value);
            }}
          />
        </div>
        <div // company_address_line2
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_address_line2.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Address line2
            {props.company_address_line2.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_address_line2.mandatory &&
              props.company_address_line2.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_address_line2"}
            type="text"
            value={Record.company_address_line2}
            disabled={props.company_address_line2.readonly}
            onChange={(event) => {
              handleFieldChange("company_address_line2", event.target.value);
            }}
          />
        </div>
        <div // company_address_city
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_address_city.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            City
            {props.company_address_city.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_address_city.mandatory &&
              props.company_address_city.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_address_city"}
            type="text"
            value={Record.company_address_city}
            disabled={props.company_address_city.readonly}
            onChange={(event) => {
              handleFieldChange("company_address_city", event.target.value);
            }}
          />
        </div>
        <div // company_address_state
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_address_state.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            State
            {props.company_address_state.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_address_state.mandatory &&
              props.company_address_state.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_address_state"}
            type="text"
            value={Record.company_address_state}
            disabled={props.company_address_state.readonly}
            onChange={(event) => {
              handleFieldChange("company_address_state", event.target.value);
            }}
          />
        </div>
        <div // company_address_pinode
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.company_address_pinode.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[150px] max-w-full"}>
            Pincode
            {props.company_address_pinode.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e]  px-2 py-1" +
              (props.company_address_pinode.mandatory &&
              props.company_address_pinode.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"company_address_pinode"}
            type="text"
            value={Record.company_address_pinode}
            disabled={props.company_address_pinode.readonly}
            onChange={(event) => {
              handleFieldChange("company_address_pinode", event.target.value);
            }}
          />
        </div>

        {/* <String name="company_name" label="Company Name" />
        <String name="company_email" label="Company E-Mail" />
        <String name="company_phone" label="Company Phone" />
        <String name="company_phone_alternative" label="Alternative Phone" />
        <String name="company_website_url" label="Website URL" />
        <Dropdown
          name="company_status"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <FileInput name="company_logo" label="Company Logo" />
        <div className="col-span-2 max-sm:col-span-1 font-semibold mt-1 px-3 hover:">
          Company Address Info
        </div>
        <String name="company_address_line1" label="Address line1" />
        <String name="company_address_line2" label="Address line2" />
        <String name="company_address_city" label="City" />
        <String name="company_address_state" label="State" />
        <String name="company_address_pinode" label="Pincode" /> */}
      </div>
    </FormView>
  );
};

export default CompanyForm;
