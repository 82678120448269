import React, { useState } from "react";

import { FaSearch, FaSortAlphaDown, FaSortAlphaDownAlt } from "react-icons/fa";
import { useStore } from "../StateProvider";
import String from "./String";
const Filters = ({ fields = [], onchange = () => {} }) => {
  const store = useStore();
  return (
    <div className="flex flex-row justify-center items-center gap-2">
      {store.getvalue("filterfield") !== "all" && (
        <button
          className="text-[14px] bg-primary h-6 w-6 rounded-full text-secondary"
          onClick={() => {
            onchange({
              field: "all",
              search: "",
              sort: store.getvalue("sort"),
            });
            store.setvalue("search", "");
            store.setvalue("filterfield", "all");
          }}
        >
          X
        </button>
      )}
      <div className="flex flex-row items-center border-2 border-primary rounded-xl h-8 gap-3 overflow-hidden w-[45%]">
        <select
          className="min-w-20 max-w-28 text-center h-full bg-primary text-secondary text-[12px]"
          onChange={(e) => {
            onchange({
              field: e.target.value,
              search: "",
              sort: store.getvalue("sort"),
            });
            store.setvalue("search", "");
            store.setvalue("filterfield", e.target.value);
          }}
          value={store.getvalue("filterfield")}
        >
          <option value="all">All</option>
          {fields.map(([name,label], index) => {
            if (!["id", "ACTION"].includes(label)) {
              return (
                <option key={index} value={label}>
                  {name}
                </option>
              );
            }
          })}
        </select>
        <String
          name="search"
          label={<FaSearch />}
          placeholder="Search"
          className={{
            field: "flex text-[18px] w-full h-full gap-3 ",
            input: "w-full",
          }}
          onchange={(e) => {
            onchange({
              field: store.getvalue("filterfield"),
              search: e,
              sort: store.getvalue("sort"),
            });
          }}
        />
      </div>
      <div
        className={
          "text-xl p-[.5%] rounded-xl " +
          (store.getvalue("sort") === "asc" ? "bg-tertiary" : "")
        }
        onClick={() => {
          store.setvalue("sort", "asc");
          onchange({
            field: store.getvalue("filterfield"),
            search: store.getvalue("search"),
            sort: "asc",
          });
        }}
      >
        <FaSortAlphaDown />
      </div>
      <div
        className={
          "text-xl p-[.5%] rounded-xl " +
          (store.getvalue("sort") === "desc" ? "bg-tertiary" : "")
        }
        onClick={() => {
          store.setvalue("sort", "desc");
          onchange({
            field: store.getvalue("filterfield"),
            search: store.getvalue("search"),
            sort: "desc",
          });
        }}
      >
        <FaSortAlphaDownAlt />
      </div>
    </div>
  );
};

export default Filters;
