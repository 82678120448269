import React from "react";
import { contentHeader } from "../../Constancs/cssconst";
import { IoChevronForwardOutline } from "react-icons/io5";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getList } from "../../functions/api";
import Calender from "../Fields/Calender";

const Schedule = ({ store, props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={
          "bg-[#EFF4FF] absolute w-full h-full bg-opacity-90 transition-all top-0 left-0 md:rounded-[15px] lg:rounded-[20px] xl:rounded-[30px] flex flex-col items-center p-3 gap-3 "
           +
          (store.getvalue("Schedule")
            ? "duration-700 translate-y-0 opacity-100 z-50"
            : "duration-700 -translate-y-6 opacity-0 -z-50")
        }
      >
        <div
          className="absolute right-5 top-5 text-[12px] bg-primary h-4 w-4 flex justify-center items-center rounded-full  text-secondary cursor-pointer"
          onClick={() => {
            store.setvalue("Schedule", undefined);
          }}
        >
          X
        </div>
        <h1
          className={
            "font-bold text-[28px]  bg-tertiary w-full text-center md:rounded-t-[15px] lg:rounded-t-[20px] xl:rounded-t-[30px] " +
            contentHeader
          }
        >
          Schedule
        </h1>
        <div
          className={
            "h-[330px] overflow-x-auto flex items-center flex-col gap-4 w-full " 
            +
            (store.getvalue("Schedule")
              ? "duration-700 translate-y-0 opacity-100 z-50"
              : "duration-700 -translate-y-10 opacity-0 -z-50")
          }
        >
          {store.getvalue("Schedule") &&
          store.getvalue("Schedule").length > 0 ? (
            store.getvalue("Schedule").map((item, index) => {
              console.log("scghedulet item ", item);
              return (
                <div
                  key={index}
                  className="bg-tertiary rounded-2xl py-1 pl-3 w-full flex items-center relative"
                  onClick={() => {
                    store.navTo("/demo/tasks/view/" + item.id);
                  }}
                >
                  <h1 className="text-primary font-semibold text-[14px]">
                    {item.subtask_title + " - "}
                    <span className="font-light">
                      {item.dueDate.split("T")[0]}
                    </span>
                    {" - " + item.status}
                  </h1>
                  <div className="absolute right-3 bg-tertiary">
                    <IoChevronForwardOutline />
                  </div>
                </div>
              );
            })
          ) : (
            <div
              // key={index}
              className="bg-secondary rounded-2xl p-3 w-full flex items-center justify-center relative h-full"
              onClick={() => {
                // store.navTo("/demo/task/view/" + item.id);
              }}
            >
              <h1 className="text-primary font-semibold text-[14px]">
                No-Record Found
              </h1>
            </div>
          )}
        </div>
      </div>

      <h1
        className={
          "font-bold text-[28px] mb-1  w-full text-center md:rounded-t-[15px] lg:rounded-t-[20px] xl:rounded-t-[30px] " +
          contentHeader
        }
      >
        Schedule
      </h1>
      <Calender
        name="Schedulecal"
        onchange={(e) => {
          console.log("Selected");
          
          getList({
            listUrl: "/subtask/getsubtasklist",
            field: "dueDate",
            search: e,
            sort: "asc",
            count: 100,
            store: store,
          }).then((res) => {
            console.log("Filtered Task ", res);
            store.setvalue("Schedule", res?.data?.list);
          });
        }}
      />
      {/* <DateCalendar
        slotProps={{
          day: {
            style: { fontWeight: "bold", height: "30px" },
          },
        }}
        sx={{ width: "85%", backgroundColor: "#EFF4FF", height: "90%" }}
        className="font-bold bg-[#EFF4FF]   h-[80%] w-[100%] rounded-xl"
        onChange={(e) => {
          getList({
            listUrl: "/subtask/getsubtasklist",
            field: "dueDate",
            search: e.format("YYYY-MM-DD"),
            sort: "asc",
            count: 100,
            store: store,
          }).then((res) => {
            console.log("Filtered Task ", res);
            store.setvalue("Schedule", res?.data?.list);
          });
        }}
      /> */}
    </LocalizationProvider>
  );
};

export default Schedule;
