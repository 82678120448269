import React, { useEffect, useState } from "react";

import { getList, GetRecords, URLPRIFIX } from "../../functions/api";
import {
  getFulllocal,
  getlocal,
  getStorage,
  setlocal,
} from "../../functions/localstorage";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiAdminLine } from "react-icons/ri";
import { MdContentPasteSearch } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { SiPhpmyadmin } from "react-icons/si";
import { useStore } from "../../Components/StateProvider";
import LoadingSpinner from "../../Components/Elements/LoadingSpinner";
import { GiHamburgerMenu } from "react-icons/gi";
import { contentHeader } from "../../Constancs/cssconst";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import "../../Components/Layout/sider.css";
import { useLocation, useParams } from "react-router-dom";
import { URL_Account_Company_List } from "../../Constancs/apiconst";

const AccountsLayout = ({ children, HeaderTitle }) => {
  const { companyId } = useParams();
  const store = useStore();
  const user = getlocal("system")?.user || {};
  const [sider, setsider] = useState("max-sm:hidden");
  const props = getlocal("properties");
  // console.log("props", props);

  let category = {};

  let menus = {
    // Dashboard: {
    //   "meta-data": { icon: <MdOutlineDashboard />, parent: true },
    //   link: `/${URLPRIFIX}/${companyId}/accounts/dashboard`,
    // },
    Master: {
      "meta-data": { icon: <SiPhpmyadmin />, parent: true },
      child: {
        "Account Groups": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/group`,
          disabled: !props?.users?.view,
        },
        "Chart of Accounts": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/ledger`,
          disabled: !props?.users?.view,
        },
        Customer: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/customer`,
          disabled: !props?.users?.view,
        },
        // Bank: {
        //   "meta-data": { parent: false },
        //   link: `/${URLPRIFIX}/${companyId}/accounts/bank`,
        //   disabled: !props?.users?.view,
        // },
      },
    },
    Transaction: {
      disabled: user?.role != "1",
      "meta-data": { icon: <RiAdminLine />, parent: true },
      child: {
        // Sales: {
        //   "meta-data": { parent: false },
        //   link: `/${URLPRIFIX}/${companyId}/accounts/sales`,
        //   // disabled: !props?.users?.view,
        // },
        Invoices: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/invoice`,
          // disabled: !props?.users?.view,
        },
        Receipts: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/receipt`,
          // disabled: !props?.users?.view,
        },
        Expenses: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/expense`,
          // disabled: !props?.users?.view,
        },
        Payables: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/payable`,
          // disabled: !props?.users?.view,
        },

        // Purchase: {
        //   "meta-data": { parent: false },
        //   link: `/${URLPRIFIX}/${companyId}/accounts/purchase`,
        //   // disabled: !props?.users?.view,
        // },
        // Payment: {
        //   "meta-data": { parent: false },
        //   link: `/${URLPRIFIX}/${companyId}/accounts/payment`,
        //   // disabled: !props?.users?.view,
        // },
        Journals: {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/journal`,
          // disabled: !props?.users?.view,
        },
      },
    },
    Reports: {
      "meta-data": { icon: <MdContentPasteSearch />, parent: true },
      child: {
        // "Journals List": {
        //   "meta-data": { parent: false },
        //   link: `/${URLPRIFIX}/${companyId}/accounts/journal`,
        //   // disabled: !props?.users?.view,
        // },
        "Day Book": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/daybook`,
          // disabled: !props?.users?.view,
        },
        "Trail Balance": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/trailbalance`,
          // disabled: !props?.users?.view,
        },
        "Customer Ledger": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/customerledger`,
          // disabled: !props?.users?.view,
        },
        "Balance Sheet": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/balancesheet`,
          // disabled: !props?.users?.view,
        },
        "Profit & Loss": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/profitloss`,
          // disabled: !props?.users?.view,
        },

        "TDS Report": {
          "meta-data": { parent: false },
          link: `/${URLPRIFIX}/${companyId}/accounts/tdsreport`,
          // disabled: !props?.users?.view,
        },
      },
    },
  };

  useEffect(() => {
    // store.setmodule("Accounts", {});

    getList({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "",
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Responces of category");
      if (res.success) {
        res?.data?.list?.map((item) => {
          category[item.categoryName] = {
            "meta-data": { icon: "", parent: false },
            link: "/" + URLPRIFIX + "/nonlitigation/" + item.categoryName,
          };
        });
      }
    });

    // Hotel: {
    //   "meta-data": { icon: "", parent: false },
    //   link: "/demo/nonlitigation/hotel",
    // },
  }, [companyId]);

  // the menu has some indicators to classify
  // every object has major components like
  //   1. "meta-data" - has the basic infor like icon location and this object is parent or not as Boolean
  //   2. child - which has the info like what are the child the going to have
  //   3. link - which create the object as a link button so we can easy to navigate to the link
  //   4. button - which help to perform any function which the store has

  return (
    <>
      <main className=" h-screen max-sm:h-svh bg-Alice_Blue text-primary font-Poppins grid grid-rows-12 relative">
        <div className=" row-span-1 max-sm:row-span-1 ">
          <Header Header={HeaderTitle} store={store} setsider={setsider} />
        </div>
        <div className="grid grid-cols-10 row-span-11 max-sm:row-span-11">
          <div
            className={
              "col-span-1 h-full bg-primary rounded-tr-[20px] Scrollleft " +
              sider
            }
          >
            <div className="innerscroll bg-primary text-secondary h-full max-sm:rounded-none max-sm:absolute max-sm:right-0 max-sm:w-full max-sm:z-50">
              <Sider menus={menus} setsider={setsider} />
            </div>
          </div>
          <div className="col-span-9 max-sm:col-span-10 overflow-x-auto px-[0.5%] py-[0.5%] relative ">
            {store.getsystemvalue("loading") && (
              <LoadingSpinner Message={"Loading"} />
            )}
            {children}{" "}
          </div>
        </div>
      </main>
    </>
  );
};

const Header = ({ Header = "", store, setsider }) => {
  const user = getlocal("system")?.user || {};
  const { companyId } = useParams();
  const location = useLocation();

  const [isPopupVisible, setPopupVisible] = useState({
    notification: false,
    profile: false,
  });
  const [Company, setCompany] = useState([]);
  const [changecompany, setchangecompany] = useState(false);
  useEffect(() => {
    getNotification();
  }, []);

  function getNotification() {
    getList({
      listUrl: "/api/SentMessage/byuserid/" + user.id,
    }).then((res) => {
      console.log("notification", res);
      store.setsystemvalue("Notification", res?.data);
    });
  }
  const togglePopup = (field) => {
    let newfield = {};
    if (field === "notification") {
      newfield = {
        notification: !isPopupVisible.notification,
        profile: false,
      };
    } else {
      newfield = {
        notification: false,
        profile: !isPopupVisible.profile,
      };
    }
    setPopupVisible((prev) => {
      prev = newfield;
      return { ...prev };
    });
  };

  function LoadCompany() {
    GetRecords(URL_Account_Company_List).then((res) => {
      if (res.success) {
        res = res?.record?.list || [];
        setCompany(res);
      }
    });
  }

  return (
    <div
      className="grid grid-cols-10 h-full shadow-sm shadow-primary"
      key={companyId}
    >
      {changecompany && <LoadingSpinner Message={"Switching Company"} />}
      <div className=" col-span-1 ">
        <div
          className="flex justify-center items-center h-full px-[6%] gap-2 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            store.navTo(
              "/" + URLPRIFIX + "/" + companyId + "/accounts/invoices"
            );
          }}
        >
          <img
            src="/Assets/images/Logo.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[42px] md:h-[30px] lg:w-[40px] lg:h-[30px] "
          />
          {/* <h1
            className="font-bold max-sm:text-[28px] md:text-[12px] lg:text-[16px] xl:text-[20px] max-sm:hidden cursor-pointer z-50"
            onClick={() => {}}
          >
            Absolute Legal Law Firm
          </h1> */}
        </div>
      </div>
      <div className=" col-span-7">
        <div className="flex items-center h-full pl-[2%]  font-bold max-sm:text-[38px] md:text-[30px] lg:text-[30px]">
          {Header}
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex items-center justify-center h-full md:gap-6 lg:gap-8 xl:gap-12 max-sm:hidden relative">
          <img
            src="/Assets/images/notification.png"
            alt="Notification"
            id="notification"
            onClick={(e) => {
              e.preventDefault();
              togglePopup("notification");
              getNotification();
              console.log("On click detected ");
            }}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[30px] lg:h-[30px] cursor-pointer z-50 hover:scale-110 hover:animate-pulse hover:duration-150 "
          />

          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[320px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.notification
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-100"
                : " -Z-10 opacity-0 -translate-y-32 translate-x-4 scale-50 duration-500")
            }
          >
            <h1 className={contentHeader}>Notifications</h1>

            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.notification
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() =>
                isPopupVisible.notification && togglePopup("notification")
              }
            >
              &times;
            </button>

            {store.getsystemvalue("Notification") &&
            store.getsystemvalue("Notification").length > 0 ? (
              <div className="overflow-y-auto max-h-[250px]">
                {store.getsystemvalue("Notification").map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 p-1 pt-5 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative"
                  >
                    {/* <div className="absolute right-1  hover:bg-[#a9a9ff] hover:bg-opacity-45 ">
                      <IoChevronForwardOutline className="w-6 h-6" />
                    </div> */}
                    <h4 className="text-sm font-bold">{item.title}</h4>
                    <p className="text-sm my-3">{item.body}</p>
                    {/* <p className="text-sm">
                      {`${item.sentAt.split("T")[0]} at ${item.sentAt
                        .split("T")[1]
                        .substring(0, 5)}`}
                    </p> */}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600 text-sm">You have no notifications</p>
            )}
          </div>

          <img
            src="/Assets/images/profile.png"
            alt="Logo"
            onClick={() => {
              togglePopup("profile");
              !isPopupVisible.profile && LoadCompany();
            }}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[30px] lg:h-[30px] cursor-pointer z-50 hover:scale-110"
          />
          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[220px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.profile
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-100 translate-x-0"
                : " -Z-10 opacity-0 -translate-y-24 translate-x-10 scale-50 duration-500")
            }
          >
            <div>
              <h1 className={contentHeader}>{user.name?.toUpperCase()}</h1>
              <h1 className={"text-[12px]"}>profile</h1>
            </div>
            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.profile
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() => isPopupVisible.profile && togglePopup("profile")}
            >
              &times;
            </button>
            <div className="overflow-y-auto max-h-[250px]">
              <div className={"mb-2 p-1 pt-3 border-b border-gray-300"}>
                <select
                  className="w-full h-full hover:bg-tertiary hover:bg-opacity-45"
                  value={user.company}
                  onChange={(event) => {
                    let local = getFulllocal();
                    local.system.user = {
                      ...local?.system?.user,
                      company: event?.target?.value,
                    };

                    setlocal(local);
                    window.location.replace(
                      "/" +
                        URLPRIFIX +
                        "/" +
                        event.target.value +
                        "/accounts/invoices"
                    );
                    // store.navTo(
                    //   "/" +
                    //     URLPRIFIX +
                    //     "/" +
                    //     event.target.value +
                    //     "/accounts/invoices"
                    // );
                  }}
                >
                  {Company.map((company, index) => (
                    <option value={company.id}>{company.company_name}</option>
                  ))}
                </select>
              </div>
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.navTo("/" + URLPRIFIX + "/accounts");
                  }}
                >
                  Non-Litigation
                </h1>
              </div>
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.navTo("/" + URLPRIFIX + "/users/profile/" + user.id);
                    isPopupVisible.profile && togglePopup("profile");
                  }}
                >
                  My Profile
                </h1>
              </div>

              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.logout();
                  }}
                >
                  Logout
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center justify-center h-full md:gap-6 lg:gap-8 max-sm:flex hidden">
          <div
            className="text-[38px]"
            onClick={(e) => {
              e.preventDefault();
              setsider((prev) => {
                if (prev === "max-sm:hidden") {
                  prev = "max-sm:block";
                } else {
                  prev = "max-sm:hidden";
                }
                return prev;
              });
            }}
          >
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sider = ({ menus, setsider }) => {
  const store = useStore();
  // the menu has some indicators to classify
  // every object has major components like
  //   1. "meta-data" - has the basic infor like icon location and this object is parent or not as Boolean
  //   2. child - which has the info like what are the child the going to have
  //   3. link - which create the object as a link button so we can easy to navigate to the link
  //   4. button - which help to perform any function which the store has

  const [sidemenu, setsidemenu] = useState(menus);

  function open(menu, open) {
    // setsidemenu(!sidemenu);

    menu = menu.replace("`", "");
    let position = "";
    menu.split("`").map((item) => (position += "['" + item + "'].child"));
    position = position.substring(0, position.length - 6);

    position =
      "sidemenu" +
      position +
      "['meta-data'].open = !sidemenu" +
      position +
      "['meta-data'].open";

    eval(position);
    // console.log("menus", sidemenu);
    setsidemenu((prev) => {
      prev = sidemenu;
      return { ...prev };
    });
  }

  return (
    <div className="flex flex-col gap-1 pl-[5%] py-[8%]">
      <Menus obj={sidemenu} open={open} parent={""} setsider={setsider} />
    </div>
  );
};

function Menus({ obj, parent, open, setsider }) {
  return Object.keys(obj).map((head, index) => {
    // console.log("new log", obj[head], obj[head]["meta-data"].icon);
    if (!obj[head].disabled) {
      return (
        <Menu
          key={index}
          heading={head}
          icon={obj[head]["meta-data"].icon}
          parent={obj[head]["meta-data"].parent}
          onClick={() => {
            open(parent + "`" + head, obj[head]["meta-data"].open);
          }}
          open={obj[head]["meta-data"].open}
          link={obj[head].link}
          button={obj[head].button}
          setsider={setsider}
        >
          {" "}
          {obj[head].child && (
            <Menus
              obj={obj[head].child}
              open={open}
              parent={parent + "`" + head}
              setsider={setsider}
            />
          )}
        </Menu>
      );
    }
  });
}

function Menu({
  heading,
  icon,
  parent,
  children,
  onClick,
  open,
  link,
  button,
  setsider,
}) {
  const store = useStore();
  return (
    <>
      <div
        className={
          parent
            ? "flex flex-col items-start justify-center mb-[2%] gap-0 cursor-pointer "
            : "flex items-center justify-start my-[5%] gap-2 cursor-pointer hover:bg-[#3c3c6a]"
        }
        onClick={(e) => {
          e.preventDefault();
          if (!link && !button) {
            onClick();
          }
          if (link && !button) {
            // store.reset({});
            store.navTo(link);
            setsider("max-sm:hidden");
          }
          if (button !== undefined && link === undefined) {
            switch (button.toLowerCase()) {
              case "logout":
                store.logout();
                setsider("max-sm:hidden");
                break;

              default:
                break;
            }
          }
        }}
      >
        <div className="text-[24px]">{icon}</div>
        <div className="flex items-center gap-3">
          <h1
            className={
              parent
                ? // ? "max-sm:text-[30px] md:text-[14px] lg:text-[18px] "
                  "max-sm:text-[26px] md:text-[9px] lg:text-[12px] "
                : "max-sm:text-[26px] md:text-[9px] lg:text-[12px] "
            }
          >
            {heading}
          </h1>
          {!link && !button && (open ? <FaChevronDown /> : <FaChevronRight />)}
        </div>
      </div>
      {parent && <hr className="w-[80%]" />}
      <div className=" md:ml-[12PX] lg:ml-[15PX] border-l-[1px] border-secondary">
        {open && children}
      </div>
    </>
  );
}

export default AccountsLayout;
