import { useStore } from "../StateProvider";
import {
  FaBackwardFast,
  FaBackwardStep,
  FaForwardFast,
  FaForwardStep,
} from "react-icons/fa6";

const Pagination = ({ onchange = () => {} }) => {
  const store = useStore();
  return (
    <div className="flex gap-3 items-center ">
      <FaBackwardFast
        className="cursor-pointer"
        onClick={() => {
          store.setvalue("currentpage", "1");
          onchange(1);
        }}
      />
      <FaBackwardStep
        className="cursor-pointer"
        onClick={() => {
          let init = Number(store.getvalue("currentpage"));
          let total = store.getvalue("totalpage");
          if (init > 1) {
            store.setvalue("currentpage", init - 1);
            onchange(init - 1);
          }
        }}
      />
      <input
        className="w-6 border text-center"
        type="text"
        value={store.getvalue("currentpage")}
        onChange={(e) => {
          let num = Number(e.target.value).toString();
          if (num == 0) {
            store.setvalue("currentpage", "");
            onchange("");
          } else if (Number(num) > store.getvalue("totalpage")) {
            store.setvalue("currentpage", "");
            onchange(1);
          } else if (num != "NaN") {
            store.setvalue("currentpage", num);
            onchange(num);
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            store.setvalue("currentpage", "1");
            onchange(1);
          }
        }}
      />
      <h1 style={{ userSelect: "none" }}>of {store.getvalue("totalpage")}</h1>
      <FaForwardStep
        className="cursor-pointer"
        onClick={() => {
          let init = Number(store.getvalue("currentpage"));
          let total = store.getvalue("totalpage");
          if (init < total) {
            store.setvalue("currentpage", init + 1);
            onchange(init + 1);
          }
        }}
      />
      <FaForwardFast
        className="cursor-pointer"
        onClick={() => {
          store.setvalue("currentpage", store.getvalue("totalpage"));
          onchange(store.getvalue("totalpage"));
        }}
      />
    </div>
  );
};

export default Pagination;
